<template>
  <!-- 需要设置改div的宽度和长度才能显示 -->
  <div key="LineECharts" id="Foldline"></div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex'
export default {
  props: {
	  FoldDate: {
		  type: [String,Object],
		  default: function() {
		   return '';
		  }
	  },
	  
  },
  data() {
    return {
		myChart:null,
		BackgroundImg:sessionStorage.getItem('BgColorType'),
		lineStyleColor:'',
		Legendcolor:'',
	}
  },
  computed:{
    ...mapGetters(['expansion'])
  },
  watch: {
    expansion(v){
			if (this.myChart) {
				this.myChart.resize();
			}
    },
	'$store.state.testTxt.tips'(newVal,oldVal){
		if(newVal=='white'){
			this.lineStyleColor = '#f6f6f6';
			this.Legendcolor = '#333333';
		}else{
			this.lineStyleColor = '#2a333a';
			this.Legendcolor = '#ffffff';
		}
		this.initEcharts(this.FoldDate);
	},
	FoldDate(newValue){
	   document.getElementById('Foldline').style.display='none';
	   if(newValue!=null){
		   document.getElementById('Foldline').style.display='block';
		  this.initEcharts(newValue); 
	   }
	}
  },
  mounted() {
    //初始echarts颜色
    if(this.BackgroundImg=='white'){
    	this.lineStyleColor = '#f6f6f6';
    	this.Legendcolor = '#333333'
    }else{
    	this.lineStyleColor = '#2a333a';
    	this.Legendcolor = '#ffffff';
    }
  },
  methods: {
    initEcharts(res) {
      // 在组件中直接使用this.$echarts来进行操作
	  document.getElementById('Foldline').style.width='100%';
	  document.getElementById('Foldline').style.height='100%';
	  this.$echarts.init(document.getElementById('Foldline')).dispose();
	  this.myChart = this.$echarts.init(document.getElementById("Foldline"));
	  let MeasureList = [];
	  let MeasureSeries = [];
	  let obj = {};
	  let Maxnum = [];
	  for(let i=0;i<res.Measure.length;i++){
	  		  MeasureList.push(res.Measure[i].name[0]);
	  			  const arr = res.Measure[i].data;
	  			  const max = Math.max.apply(null, arr);
	  			obj = {
	  			  name:res.Measure[i].name[0],
	  			  type: 'line',
	  			  itemStyle: {
	  			    color: '#ff9600',
	  			  },
	  			  data: res.Measure[i].data,
	  			  symbolSize:8,
	  			  smooth: false,//面积图改成弧形状
	  			  lineStyle: {
	  			  width: 2,//外边线宽度
	  			  color:'#ff9600',//外边线颜色
	  			  },
	  			  areaStyle: {//区域填充渐变颜色
	  			  color: {
	  				  type: 'linear',
	  				  x: 0,
	  				  y: 0,
	  				  x2: 0,
	  				  y2: 1,
	  				  colorStops: [{
	  				  	offset: 0, color: 'rgba(255,150,0, 1)' // 0% 处的颜色
	  				  }, {
	  				  	offset: 1, color: 'rgba(255,246,234, 0.5)' // 100% 处的颜色
	  				  }],
	  				  global: false // 缺省为 false
	  			  }
	  			  }
	  			}, 
	  		MeasureSeries.push(obj)
	  }
	  var Numbers = Math.max.apply(null, Maxnum)/1;
	  var test = '';
	  if(Numbers<=eval(100)){
	  		test = 30;
	  }else if(Numbers<=eval(1000)){
	  		test = 35
	  }else if(Numbers<=eval(10000)){
	  		test = 45
	  }else{
	  		test = 50
	  }
      // 指定图表的配置项和数据
      var option = {
		  tooltip: {
			trigger: 'axis',
			showContent: true,
			formatter: function (params) {
			  for (let i = 0; i < params.length; i++) {
			    var relVal = params[i].name;
			  }
			  for (let i = 0; i < params.length; i++) {
			      let unit = "%";
			      relVal +=
			      "<br/>" +
			      params[i].marker +
			      params[i].seriesName +
			      " : " +
			      params[i].data +' '+
			      unit;
			  }
			  return relVal;
			},
		  },
		  legend: {
			x:'right',      //可设定图例在左、右、居中
			y:'top',     //可设定图例在上、下、居中
			itemWidth: 12, // 标签宽度为20px
			itemHeight: 12,
			top:10,
			padding:[0,30,0,0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
			data: MeasureList,
			textStyle: {
				color:this.Legendcolor
			}
		  },
		  grid: {
			x:30,
			y:35, 
			x2:50, 
			y2:15, 
			containLabel: true 
		  },
		  xAxis: {
			type: 'category',
			boundaryGap: false,
			data: res.Dimension,
			axisPointer: {
				type: 'shadow'
			},
			axisTick:{
			   show:false
			},
			axisLine: {
			  lineStyle: {
				  width: 0,
				  type: "solid"
			  }
			},
			axisLabel: {
			  textStyle: {
				  color:this.Legendcolor
			  }
			},
		  },
		  yAxis:[
			  {
			    type: 'value',
			    name: res.MeasureUnit.Y1,
				nameTextStyle:{//y轴上方单位的颜色
				  color:this.Legendcolor
				},
				min: 0,
			    nameGap: test,
			    nameLocation: "middle",
			    axisLabel: {
				  textStyle: {
					  color:this.Legendcolor
				  }
			    },
			    axisTick:{
			       show:false
			    },
			    axisLine: {
			  	  lineStyle: {
			  		  width: 0,
			  		  type: "solid"
			  	  }
			    },
			    splitLine:{
			  	show:true,
			  	lineStyle:{
			  	//这里输入线条的样式
			  	  color:this.lineStyleColor,
			  	}
			    }
			  },
		  ],
		  series: MeasureSeries
		};
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
	  window.addEventListener("resize", () => {
		this.initEcharts()
		this.myChart.resize();
	  })
    },
  },
};
</script>

<style>
</style>
