<template>
  <!-- 需要设置改div的宽度和长度才能显示 -->
  <div key="PicCharts" :id="num+'chart'"></div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex'
export default {
  props: {
	  PicDate: {
	    type: Array,
	    default: () => [],
	  },
	  color: {
		type: Object,
		default: ''
	  },
	  num: {
	  		type:Number,
	  		default: ''
	  }
  },
  data() {
    return {
		myChart:null,
		ColorObj:[],
		DateObj:[],
	}
  },
  computed:{
    ...mapGetters(['expansion'])
  },
  watch: {
    expansion(v){
			if (this.myChart) {
				this.myChart.resize();
			}
    },
  },
  mounted() {
    document.getElementById(this.num+'chart').style.display='none';
    if(this.PicDate.length>0){
	   document.getElementById(this.num+'chart').style.display='block';
	   this.initEcharts(this.PicDate); 
    }
  },
  methods: {
    initEcharts(res) {
	  this.ColorObj = [];
	  this.DateObj = [];
      // 在组件中直接使用this.$echarts来进行操作
	  document.getElementById(this.num+'chart').style.width='100%';
	  document.getElementById(this.num+'chart').style.height='100%';
	  this.$echarts.init(document.getElementById(this.num+'chart')).dispose();
	  this.myChart = this.$echarts.init(document.getElementById(this.num+'chart'));
	  this.ColorObj.push(this.color.color,this.color.color1);
	  for(let i=0;i<res.length;i++){
		  let obj ={
			value:res[i].Value,
			name: res[i].Name
		  }
		 this.DateObj.push(obj)
	  }
	  this.DateObj[0].selected = true;
	  this.DateObj[0].label = {
		  show:true,     //默认显示第一块
		  fontSize: '15',
		  fontWeight: 'bold',
			color: '#fff'
	  };
      // 指定图表的配置项和数据
      var option = {
			color:this.ColorObj,
			tooltip: {
				trigger: 'item'
			},
			series: [
					{
						type: 'pie',
						radius: ['50%', '70%'],
						avoidLabelOverlap: false,
						hoverAnimation:false,   //关闭放大动画
						selectedOffset:0,     //选中块的偏移量
						label: {
							show: false,
							position: 'center',
							formatter: '{d}',
							fontFamily: 'GemunuLibre-Regular',
						},
						
						labelLine: {
							show: false
						},
						data:this.DateObj
					}
				]
		};
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
	  window.addEventListener("resize", () => {
		this.initEcharts()
		this.myChart.resize();
	  })
    },
  },
};
</script>

<style>
</style>
