<template>
  <!-- 需要设置改div的宽度和长度才能显示 -->
  <div key="FaultmyCharts" id="Faultmain"></div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex'
export default {
  props: {
	  echartData: {
		 type: [String,Object],
		 default: function() {
		  return '';
		 }
	  }
  },
  data() {
    return {
		myChart:null,
		BackgroundImg:sessionStorage.getItem('BgColorType'),
		lineStyleColor:'',
		Legendcolor:'',
	}
  },
  computed:{
    ...mapGetters(['expansion'])
  },
  watch: {
    expansion(v){
			if (this.myChart) {
				this.myChart.resize();
			}
    },
	'$store.state.testTxt.tips'(newVal,oldVal){
		if(newVal=='white'){
			this.lineStyleColor = '#f6f6f6';
			this.Legendcolor = '#333333';
		}else{
			this.lineStyleColor = '#2a333a';
			this.Legendcolor = '#ffffff';
		}
		this.initEcharts(this.echartData);
	},
	echartData(newValue){
		document.getElementById('Faultmain').style.display='none';
		if(newValue!=null){
		   document.getElementById('Faultmain').style.display='block';
		   this.initEcharts(newValue); 
		}
	},
  },
  mounted() {
	//初始echarts颜色
	if(this.BackgroundImg=='white'){
		this.lineStyleColor = '#f6f6f6';
		this.Legendcolor = '#333333'
	}else{
		this.lineStyleColor = '#2a333a';
		this.Legendcolor = '#ffffff';
	}
  },
  methods: {
    initEcharts(res) {
      // 在组件中直接使用this.$echarts来进行操作
	  document.getElementById('Faultmain').style.width='100%';
	  document.getElementById('Faultmain').style.height='100%';
	  this.$echarts.init(document.getElementById('Faultmain')).dispose();
	  this.myChart = this.$echarts.init(document.getElementById("Faultmain"));
	  let MeasureList = [];
	  let MeasureSeries = [];
	  let color = '';
	  let BarLine = '';
	  let obj = {};
	  let Maxnum = [];
	  for(let i=0;i<res.Measure.length;i++){
		  MeasureList.push(res.Measure[i].name[0]);
		  if(res.Measure[i].name[0]=='故障次数'){
			color = '#38cb89';
			BarLine = 'bar'
		  }else if(res.Measure[i].name[0]=='持续时长'){
			color = '#ff9600'; 
			BarLine = 'bar'
		  }
		  const arr = res.Measure[i].data;
		  const max = Math.max.apply(null, arr);
		  Maxnum.push(max)
			obj = {
			  name: res.Measure[i].name[0],
			  type: BarLine,
			  tooltip: {
				valueFormatter: function (value) {
				  return value;
				}
			  },
			  itemStyle: {
				color:color,
				barBorderRadius: 8//柱子的圆角
			  },
			  data:res.Measure[i].data,
			  barWidth: 8,
			 } 
		MeasureSeries.push(obj)
	  }
	  var Numbers = Math.max.apply(null, Maxnum)/1;
	  var test = '';
	  if(Numbers<=eval(100)){
			test = 30;
	  }else if(Numbers<=eval(1000)){
			test = 35
	  }else if(Numbers<=eval(10000)){
			test = 45
	  }else{
			test = 50
	  }
      // 指定图表的配置项和数据
      var option = {
			  tooltip: {
				trigger: 'axis',
				axisPointer: {
				  type: 'cross',
				  crossStyle: {
					color: '#999'
				  }
				}
			  },
			  legend: {
				x:'right',      //可设定图例在左、右、居中
				y:'top',     //可设定图例在上、下、居中
				itemWidth: 12, // 标签宽度为20px
				itemHeight: 12,
				top:10,
				padding:[0,30,0,0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
				data: MeasureList,
				textStyle: {
					color:this.Legendcolor
				}
			  },
			  grid: {  //方法 2
				x:30, 
				y:35, 
				x2:30, 
				y2:15, 
				containLabel: true 
			  },	
			  xAxis: [
				{
				  type: 'category',
				  data: res.Dimension,
				  axisPointer: {
					type: 'shadow'
				  },
				  axisTick:{
				     show:false
				  },
				  axisLine: {
					  lineStyle: {
						  width: 0,
						  type: "solid"
					  }
				  },
				  axisLabel: {
					textStyle: {
						color:this.Legendcolor
					}
				  },
				}
			  ],
			  yAxis: [
				{
				  type: 'value',
				  name: res.MeasureUnit.Y1,
				  nameTextStyle:{//y轴上方单位的颜色
				  	color:this.Legendcolor
				  },
				  min: 0,
				  // max: 120,
				  nameLocation: "middle",
				  nameGap:test,
				  axisLabel: {
					textStyle: {
					  color:this.Legendcolor
					}
				  },
				  axisTick:{
				     show:false
				  },
				  axisLine: {
					  lineStyle: {
						  width: 0,
						  type: "solid"
					  }
				  },
				  splitLine:{
					show:true,
					lineStyle:{
					//这里输入线条的样式
					  color:this.lineStyleColor,
					}
				  }
				},
				{
				  type: 'value',
				  name: res.MeasureUnit.Y2,
				  min: 0,
				  // max: 120,
				  // interval: 30,
				  nameLocation: "middle",
				  nameGap: test,
				  axisLabel: {
					  textStyle: {
						  color:this.Legendcolor
					  }
				  },
				  axisTick:{
				     show:false
				  },
				  axisLine: {
					  lineStyle: {
						  width: 0,
						  type: "solid"
					  }
				  },
				  splitLine:{
					show:true,
					lineStyle:{
					//这里输入线条的样式
					   color:this.lineStyleColor,
					}
				  },
				}
			  ],
			  series:MeasureSeries
			};
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
	  window.addEventListener("resize", () => {
		this.initEcharts()
		this.myChart.resize();
	  })
    },
  },
};
</script>

<style>
</style>
