<template>
	<div class="PlannedManagement">
		<div class="gaugeoutfit2">
			<span class="xian"></span>
			<span class="title">当前工作计划</span>
		</div>
		<el-row :gutter="20" class="Planned_row">
		  <el-col :span="6">
		   <div class="Yieldcomparison_l">
			   <div class="RegionalImg"
			   v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
			   	<el-image class="img" :src="Bgurl" :preview-src-list="srcList"></el-image>
				<span v-show="emptyMsg" class="icon">
				  <img :src="empty" class="icon-img">
				  <div class="icon-text">暂无数据</div>
				</span>
			   </div> 
			   <div class="RegionalList">
			   	<span class="span1" v-if="MonitorList.EquipmentStatus==0">
			   		设备状态：
			   		<span class="span2" style="color: #ff9600!important;">{{MonitorList.EquipmentStatusName}}</span>
			   	</span>
			   	<span class="span1" v-if="MonitorList.EquipmentStatus==3">
			   		设备状态：
			   		<span class="span2" style="color: #38cb89!important;">{{MonitorList.EquipmentStatusName}}</span>
			   	</span>
			   	<span class="span1" v-if="MonitorList.EquipmentStatus==5">
			   		设备状态：
			   		<span class="span2" style="color: #f14848!important;">{{MonitorList.EquipmentStatusName}}</span>
			   	</span>
			   	<span class="span1" v-if="MonitorList.EquipmentStatus==-1">
			   		设备状态：
			   		<span class="span2" style="color: #cccccc!important;">{{MonitorList.EquipmentStatusName}}</span>
			   	</span>
			   </div>
			   <div class="RegionalList">
			   	<span class="span1">设备名称：<span class="span2">{{MonitorList.EquipmentName}}</span></span>
			   </div>
			   <div class="RegionalList">
			   	<span class="span1">设备厂商：<span class="span2">{{MonitorList.ModelName}}</span></span>
			   </div>
			   <div class="RegionalLastList">
			   	<span class="span1">设备型号：<span class="span2">{{MonitorList.EquipmentTypeName}}</span></span>
			   </div>
		   </div>
		  </el-col>
		  <el-col :span="18">
		  	<div class="Yieldcomparison_r">
					<div class="PlanSubject FilterCriteria">
						<!--设备名称-->
						<div class="inputStyle">
							<span class="SelectText">设备名称：</span>
							<div class="SelectInput">
								<span>{{MonitorList.EquipmentName}}</span>
							</div>
						</div>
						<!--计划名称-->
						<PlanName @selectedLocations="selectPlanName" ref="PlanSelect" :EquipmentId="EquipmentId" class="selectStyle"></PlanName>
						<div class="inputStyle">
							<span class="SelectText">时间周期：</span>
							<el-radio-group v-model="dateType" size="mini">
								<el-radio-button class="radioBtn" label="year">年</el-radio-button>
								<el-radio-button class="radioBtn" label="month">月</el-radio-button>
								<el-radio-button class="radioBtn" label="week">周</el-radio-button>
								<el-radio-button class="radioBtn" label="day">日</el-radio-button>
							</el-radio-group>
						</div>
						<div class="inputStyle time_row" v-if="dateType=='year' || dateType=='month' || dateType=='week'">
							<span class="SelectText">开始时间：</span>
							<el-date-picker
								v-model="BeginTime2"
								:clearable="false"
								:editable="false"
								type="date"
								style="width: 10vw;"
								placeholder="选择日期时间"
							></el-date-picker>
						</div>
						<div class="inputStyle time_row" v-if="dateType=='day'">
							<span class="SelectText">开始时间：</span>
							<el-date-picker
								v-model="BeginTime2"
								:clearable="false"
								:editable="false"
								type="date"
								style="width: 10vw;"
								:pickerOptions="pickerOptions"
								placeholder="选择日期时间"
							></el-date-picker>
						</div>
						<div class="inputStyle time_row">
							<span class="SelectText">结束时间：</span>
							<el-date-picker
								v-model="EndTime2"
								:clearable="false"
								:editable="false"
								type="date"
								style="width: 10vw;"
								:pickerOptions="pickerOptions"
								placeholder="选择日期时间"
							></el-date-picker>
						</div>
					</div>
					<!--时间表格-->
					<div class="timeTable">
						<div class="Startuptime">
							<div class="Startuptime_l">开机时间</div>
							<div class="Startuptime_r timeselect">
								<el-time-select
									v-model="StartClockStr"
									:picker-options="{
										start: '00:00',
										step: '00:15',
										end: '24:00'
									}"
							style="width:10vw;"
									:clearable="false"
									placeholder="请选择时间">
								</el-time-select>
							</div>
						</div>
						<div class="Startuptime">
							<div class="Startuptime_l">关机时间</div>
							<div class="Startuptime_r timeselect">
								<el-time-select
									v-model="EndClockStr"
									:picker-options="{
										start: '00:00',
										step: '00:15',
										end: '24:00'
									}"
							style="width:10vw;"
									:clearable="false"
									placeholder="请选择时间">
								</el-time-select>
							</div>
						</div>
						<div class="cycle">
							<div class="cycle_l">周期</div>
							<div class="cycle_r">
								<el-radio-group v-model="radio" class="radiostyle">
										<el-radio label="Everyday">每天</el-radio>
										<el-radio label="Optional">自选</el-radio>
								</el-radio-group>
								<el-checkbox-group v-model="checkList" @change="handleCheckedCitiesChange" class="checkboxstyle">
										<el-checkbox label="周一"></el-checkbox>
										<el-checkbox label="周二"></el-checkbox>
										<el-checkbox label="周三"></el-checkbox>
										<el-checkbox label="周四"></el-checkbox>
										<el-checkbox label="周五"></el-checkbox>
										<el-checkbox label="周六"></el-checkbox>
										<el-checkbox label="周日"></el-checkbox>
								</el-checkbox-group>
								<!--time-->
								<DatePicker @TimeSelect="TimeSelects($event)" ref="child" :BeginTime2="BeginTime2" :EndTime2="EndTime2" :DateTime="DateTime"></DatePicker>
							<el-button type="success" size="small" class="btn" @click="BlectricityQuery('ruleForm')">保存</el-button>
						</div>
						</div>
					</div>
		  	</div>
		  </el-col>
		</el-row>
	</div>
</template>

<script>
import { formatDate,getDayOfNextYear,getNextMonth,getDayOfPreWeek,addDate } from '@/common/js/date.js'
import { isEmpty,convert } from '@/common/js/base.js'
import empty from '@/assets/icons/empty02.png'
import error from '@/assets/icons/error.png'
import PlanName from '@/components/select/PlanName'
import DatePicker from '@/components/DateTime/DatePicker'

import { GetEquipmentImage,GetSelectedEquipmentMonitor,GetDayWorkPlanById,AddEquipmentWorkPlan,UpdateEquipmentWorkPlan} from "@/api/EquipmentOperationMonitoring"; //页面接口
export default {
  name: "produce",
  props: {
    EquipmentId:{
      type: [String,Number],
      default: ''
    },
  },
  components: {
	PlanName,
	DatePicker
	},
  data() {
    return {
		empty:empty,
		error:error,
		loading:false,
		Bgurl:null,
		loading:false,
		emptyMsg:true,
		srcList:[],
		
		loading2:false,
		emptyMsg2:true,
		
		MonitorList:[],
		
		DeviceNameId:'',
		PlanNameId:'',
		
		dateType: 'day',
		SelectDate: formatDate(new Date(), 'yyyy'),
		BeginTime2:formatDate(new Date(), 'yyyy-MM-dd'),
		EndTime2:addDate(formatDate(new Date(), 'yyyy-MM-dd'), 1),
		pickerOptions: {//禁用未来日期
		    onPick: ({ maxDate, minDate }) => {
		      this.pickerMinDate = minDate.getTime()
		      if (maxDate) {
		        this.pickerMinDate = ''
		      }
		    },
		    disabledDate: (time) => {
		      if (this.pickerMinDate !== '') {
		        const day30 = (30 - 1) * 24 * 3600 * 1000
		        let maxTime = this.pickerMinDate + day30
		        if (maxTime > new Date()) {
		          maxTime = new Date()
		        }
		        return time.getTime() > maxTime
		      }
		      return time.getTime() > Date.now()
		    }
		},
		StartClockStr:'',
		EndClockStr:'',
		radio:'Everyday',
		checkList: ['周一','周二','周三','周四','周五','周六','周日'],
		DateTime:['周一','周二','周三','周四','周五','周六','周日'],
		createTime:null,
		distinguish:null,
		DayWorkPlanId:'',
		dayWorkPlanName:'',
    };
  },
  watch: {
	EquipmentId(newValue) {
	   	// console.log(newValue,'treeId')
		this.EquipmentId = newValue;
		this.GetEquipmentImages(newValue);
		//
		this.GetSelectedEquipmentMonitors(newValue);
	},
	dateType(val) {
	  if (this.dateType == 'year') {
	    this.SelectDate = formatDate(new Date(), 'yyyy');
	    this.BeginTime2 =formatDate(new Date(this.SelectDate), 'yyyy-MM-dd').split('-')[0] + '-01-01';
	    this.EndTime2 = addDate(getDayOfNextYear(formatDate(new Date(this.SelectDate), 'yyyy-MM-dd')), + '-01');
	  } else if (this.dateType == 'month') {
	    this.SelectDate = formatDate(new Date(), 'yyyy-MM');
	    this.BeginTime2 = formatDate(new Date(this.SelectDate), 'yyyy-MM') + '-01';
	    this.EndTime2 =addDate(getNextMonth(formatDate(new Date(this.SelectDate), 'yyyy-MM-dd')), + '-01');
	  }else if (this.dateType == 'week') {
	    this.SelectDate = formatDate(new Date(), 'yyyy-MM-dd');
	    this.BeginTime2 = getDayOfPreWeek(new Date(this.SelectDate), 'yyyy-MM-dd');
	    this.EndTime2 = formatDate(new Date(this.SelectDate), 'yyyy-MM-dd');
	  } else if (this.dateType == 'day') {
	    this.SelectDate = formatDate(new Date(), 'yyyy-MM-dd');
	    this.BeginTime2 =formatDate(new Date(this.SelectDate), 'yyyy-MM-dd');
	    this.EndTime2 =addDate(formatDate(new Date(this.SelectDate), 'yyyy-MM-dd'), 1);
	  }
	},
	radio(val){
	  if(val=='Optional'){
	    this.checkList = [];
	    this.DateTime = this.checkList;
	  }else if(val=='Everyday'){
	    this.checkList = ['周一','周二','周三','周四','周五','周六','周日'];
	    this.DateTime = this.checkList;
	  }
	},
  },
  mounted() {
	//
	this.GetSelectedEquipmentMonitors(this.EquipmentId);
  },
  methods: {
	GetEquipmentImages(id){
		this.loading = true;
		this.emptyMsg = false;
		this.srcList = [];
		GetEquipmentImage({
		    EquipmentId:id
		  }).then(res => {
			  if(res.Code!=0){
				 this.$notify.info({
				   title: '消息',
				   message:'图片获取为空'
				 });
				 this.emptyMsg = true;
				 return;
			  }
			this.Bgurl = 'data:image/png;base64,'+ res.Content;
			this.srcList.push(this.Bgurl);
			this.loading = false;
		}).catch(err => {
		
		})
	},
	GetSelectedEquipmentMonitors(id){
		this.loading2 = true;
		this.emptyMsg2 = false;
		this.MonitorList = [];
		GetSelectedEquipmentMonitor({
		  AgencyId:sessionStorage.getItem('agencyId'),//机构id
		  EquipmentId:id
		}).then(res => {
		let obj = res.Content;
		 if(res.Code==-1) {
		   this.loading2 = false;
		   this.emptyMsg2 = true;
		   this.MonitorList = [];
		   this.$notify.info({
		     title:'消息',
		     message: '查询失败'
		   });
		   return;
		 }else if(obj.length == 0) {
		  this.loading2 = false;
		  this.emptyMsg2 = true;
		  this.MonitorList = [];
		   this.$notify.info({
		     title: '消息',
		     message:'数据为空'
		   });
		   return;
		 }
		 this.loading2 = false;
		 this.MonitorList = obj.MonitorList[0];
		 this.GetEquipmentImages(this.EquipmentId);
		}).catch(err => {
		this.loading2 = false;
		this.emptyMsg2 = true;
		})
	},
	selectPlanName(val,name,distinguish){
	  // console.log(val,name,distinguish,'val')
	  this.distinguish = distinguish;//编辑和新增判断；
	  this.PlanNameId = val;
	  //获取开关计划
	  if(distinguish==true){
		this.GetDayWorkPlanById_s()
		this.DayWorkPlanId = val;
		this.dayWorkPlanName = name;
	  }else{
		this.radio = 'Optional';
		this.dateType = 'year';
		this.StartClockStr = '00:00';
		this.EndClockStr = '24:00';
		this.DayWorkPlanId = -1;
		this.dayWorkPlanName = val;
	  }
	},
	//复选框
	 handleCheckedCitiesChange(value) {
	    this.checkList = value;
	    this.DateTime = value;
	    // console.log(this.checkList,'选中的值')
	  },
	async TimeSelects(value) {
	 this.createTime = value;
	 // console.log(this.createTime,'time')
	},
	GetDayWorkPlanById_s(){
		GetDayWorkPlanById({
		  id:this.PlanNameId
		}).then(res => {
		let list = res.Content;
		 if(res.Code==-1) {
		   this.MonitorList = [];
		   this.$notify.info({
		     title:'消息',
		     message: '获取开关计划失败'
		   });
		   return;
		 }else if(list.length == 0) {
		   this.$notify.info({
		     title: '消息',
		     message:'开关计划为空'
		   });
		   return;
		 }
		 for(var i=0;i<list.Periods.length;i++){
		   if(list.Periods[i]==1){
		     list.Periods[i] = '周一';
		   }else if(list.Periods[i]==2){
		     list.Periods[i] = '周二';
		   }else if(list.Periods[i]==3){
		     list.Periods[i] = '周三';
		   }else if(list.Periods[i]==4){
		     list.Periods[i] = '周四';
		   }else if(list.Periods[i]==5){
		     list.Periods[i] = '周五';
		   }else if(list.Periods[i]==6){
		     list.Periods[i] = '周六';
		   }else if(list.Periods[i]==7){
		     list.Periods[i] = '周日';
		   }
		 }
		 this.DateTime = list.Periods;
		 this.StartClockStr = list.StartTime;
		 this.EndClockStr  = list.EndTime;
		 var stat = new Date(list.StartDate);
		 var end = new Date(list.EndDate);
		 var begintime=stat.getFullYear() + '-' + (stat.getMonth() + 1) + '-' + stat.getDate();
		 var endtime=end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
		 
		 this.checkList = list.Periods;
		 this.dateType = list.DateType==1?'year':(list.DateType==2?'month':'day');
		 setTimeout(() => {
		   this.BeginTime2 = begintime;
		   this.EndTime2 = endtime;
		 },10)
		}).catch(err => {
			
		})
	},
	//编辑保存
	BlectricityQuery(ruleForm) {
	if(this.PlanNameId==''){
		this.$notify.info({
		  title: '消息',
		  message: '计划名称不能为空'
		});
		return;
	}else if(this.DateTime.length==0){
		this.$notify.info({
		  title: '消息',
		  message: '周期不能为空'
		});
		return;
	}else if(this.StartClockStr==null||this.EndClockStr==null){
		this.$notify.info({
		  title: '消息',
		  message: '开机时间或关机时间不能为空'
		});
		return;
	}else if(this.StartClockStr == this.EndClockStr){
		this.$notify.info({
		  title: '消息',
		  message: '开机时间,关机时间不能同一时间'
		});
		return;
	}
	  this.SaveData();
	},
	SaveData(){
		for(var i=0;i<this.DateTime.length;i++){
		  if(this.DateTime[i]=='周一'){
		    this.DateTime[i] = 1;
		  }else if(this.DateTime[i]=='周二'){
		    this.DateTime[i] = 2;
		  }else if(this.DateTime[i]=='周三'){
		    this.DateTime[i] = 3;
		  }else if(this.DateTime[i]=='周四'){
		    this.DateTime[i] = 4;
		  }else if(this.DateTime[i]=='周五'){
		    this.DateTime[i] = 5;
		  }else if(this.DateTime[i]=='周六'){
		    this.DateTime[i] = 6;
		  }else if(this.DateTime[i]=='周日'){
		    this.DateTime[i] = 7;
		  }
		}
		if (this.StartClockStr > this.EndClockStr) {
		  this.$notify.info({
		    title: '消息',
		    message: '开机时间不能大于关机时间'
		  });
		  return;
		}
		//
		var d = new Date(this.BeginTime2);
		var begintime=d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
		var c = new Date(this.EndTime2);
		var endtime=c.getFullYear() + '-' + (c.getMonth() + 1) + '-' + c.getDate();
		if (new Date(begintime).getTime() > new Date(endtime).getTime()) {
		  this.$notify.info({
		    title: '消息',
		    message: '开始时间不能大于结束时间'
		  });
		  return;
		}
		if(this.distinguish){//更新开机计划
			UpdateEquipmentWorkPlan({
			 "agencyId":sessionStorage.getItem('agencyId'),
			 "id":this.DayWorkPlanId,//计划名称id this.NewId
			 "OldId":this.DayWorkPlanId,
			 "equimpentId":this.EquipmentId,//设备名称id
			 "dayWorkPlanName": this.dayWorkPlanName,//计划名称name
			 "startTime": this.StartClockStr,
			 "endTime": this.EndClockStr,
			 "startDate": begintime,//this.BeginTime2
			 "endDate":endtime,//this.EndTime2
			 "periods":this.DateTime,
			 "dateType":this.dateType=='year'?1:(this.dateType=='month'?2:3)
			}).then(res => {
			 if(res.Code==-1) {
			   this.$notify.info({
			     title:'消息',
			     message:res.Message
			   });
			   return;
			 }
			 this.$notify.info({
			   title:'消息',
			   message:res.Message
			 });
			 //更新
			 this.$refs.PlanSelect.getMsg(this.PlanNameId);
			}).catch(err => {
				
			})
		}else{ //添加开机计划
			AddEquipmentWorkPlan({
			  "agencyId":sessionStorage.getItem('agencyId'),
			  "equimpentId":this.EquipmentId,//设备名称id
			  "dayWorkPlanName":this.dayWorkPlanName,
			  "DayWorkPlanId":this.DayWorkPlanId,
			  "startTime": this.StartClockStr,
			  "endTime": this.EndClockStr,
			  "startDate": begintime,//this.BeginTime2
			  "endDate":endtime,//this.EndTime2
			  "periods":this.DateTime,
			  "dateType":this.dateType=='year'?1:(this.dateType=='month'?2:3)
			}).then(res => {
			 if(res.Code==-1) {
			   this.$notify.info({
			     title:'消息',
			     message:res.Message
			   });
			   return;
			 }
			 this.$notify.info({
			   title:'消息',
			   message:res.Message
			 });
			 //更新
			 this.$refs.PlanSelect.getMsg(this.PlanNameId);
			}).catch(err => {
				
			})
		}
	},
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/mixin.scss';
.PlannedManagement{
	height: 100%;
	background: url('../../../static/images/bck_bord_big.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.Planned_row{
	height: calc(100% - 40px);
	padding: 0 15px;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
.Yieldcomparison_l{
	position: relative;
	width: 100%;
	height: 570px;
	margin-top: 20px;
	// @include background_color("background_color_white");
	border-radius: 4px;
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.RegionalImg{
	position: relative;
	width: 100%;
	height: 280px;
	margin-bottom: 20px;
	text-align: center;
}
.RegionalImg .img{
	display: inline-block;
	// width: 70%;
	// height: auto;
	height: calc(100% - 10px);
	// margin-left:15%;
	// margin-top: 15px;
	cursor: pointer;
	vertical-align: middle;
	padding-top: 10px;
}
.RegionalList{
	width: 92%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
	@include border_color("border_color_white");
}
.RegionalLastList{
	width: 92%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
.RegionalLastList .span1,
.RegionalList .span1{
	font-size: 15px;
	@include font_color("font7_color_white");
}
.RegionalLastList .span2,
.RegionalList .span2{
	font-size: 15px;
	@include font_color("font2_color_white");
}
.Yieldcomparison_r{
	width: 100%;
	height: 650px;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.selectStyle{
	margin-right: 10px;
	margin-top: 20px;
}
.inputStyle{
	display: inline-block;
	margin-right: 10px;
	margin-top: 20px;
}
.selectStyle{
	margin-right: 10px;
	margin-top: 20px;
}
.radioBtn{
	width: 48px;
	height: 30px;
}
.SelectText{
	display:inline-block;
	white-space: nowrap;
	width: 4.5vw;
	text-align: left;
	padding-left: 16px;
	font-size: 16px;
}
//表格
.timeTable{
	// width: 97%;
	height: 470px;
	// margin-left: 2%;
	margin-top:20px;
}
.Startuptime,.cycle{
    width:100%;
    height:auto;
  }
  .Startuptime_l{
    display:inline-block;
    width:16%;
    height:30px;
	font-size: 14px;
	@include font_color("font2_color_white");
    text-align: center;
    float:left;
    // border:1px solid #bbbbbb;
		@include border2_color("border2_color_white");
		@include background_color("background5_color_white");
    border-right:none;
    border-bottom: none;
    line-height: 30px;
  }
  .Startuptime_r{
      display:inline-block;
      width:83%;
      height:30px;
      float:left;
      text-align: center;
      // border:1px solid #bbbbbb;
			@include border2_color("border2_color_white");
	  	@include background_color("background5_color_white");
      border-bottom: none;
      line-height: 30px;
  }
  .cycle_l{
    display:inline-block;
    width:16%;
    height:370px;
    text-align: center;
    float:left;
	font-size: 14px;
	@include font_color("font2_color_white");
    // border:1px solid #bbbbbb;
		@include border2_color("border2_color_white");
	@include background_color("background5_color_white");
    border-right:none;
    line-height: 400px;
  }
  .cycle_r{
      display:inline-block;
      width:83%;
      height:370px;
      float:left;
      text-align: center;
      position: relative;
      // border:1px solid #bbbbbb;
			@include border2_color("border2_color_white");
	  @include background_color("background5_color_white");
      margin-bottom: 18px;
  }
  .radiostyle{
    width: 100%;
    white-space: nowrap;
    text-align: left;
    position: absolute;
    top:14px;
    left: 12px;
  }
  .checkboxstyle{
    width: 100%;
    white-space: nowrap;
    top:10px;
    position: absolute;
    text-align: left;
    top:37px;
    left: 12px;
  }
  .SelectInput{
	  display: inline-block;
	  width: 10vw;
	  height: 30px;
	  line-height: 30px;
	  text-align: left;
	  font-size: 15px;
	  @include border2_color("border2_color_white");
		background-color: #1f2930 !important;
  }
  .SelectInput span{
	  display: inline-block;
	  margin-left: 10px;
  }
  .btn{
	  float: right;
	  margin-top: 20px;
  }
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
.Yieldcomparison_l{
	position: relative;
	width: 100%;
	height: 57vh;
	margin-top: 2vh;
	// @include background_color("background_color_white");
	border-radius: 4px;
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.RegionalImg{
	position: relative;
	width: 100%;
	height: 28vh;
	margin-bottom: 2vh;
	text-align: center;
}
.RegionalImg .img{
	display: inline-block;
	// width: 70%;
	// height: auto;
	height: calc(100% - 10px);
	// margin-left:15%;
	// margin-top: 1.5vh;
	cursor: pointer;
	vertical-align: middle;
	padding-top: 10px;
}
.RegionalList{
	width: 92%;
	height: 5vh;
	line-height: 5vh;
	margin: 0 auto;
	@include border_color("border_color_white");
}
.RegionalLastList{
	width: 92%;
	height: 5vh;
	line-height: 5vh;
	margin: 0 auto;
}
.RegionalLastList .span1,
.RegionalList .span1{
	font-size: 15px;
	@include font_color("font7_color_white");
}
.RegionalLastList .span2,
.RegionalList .span2{
	font-size: 15px;
	@include font_color("font2_color_white");
}
.Yieldcomparison_r{
	width: 100%;
	height: 65vh;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.selectStyle{
	margin-right: 10px;
	margin-top: 2vh;
}
.inputStyle{
	display: inline-block;
	margin-right: 10px;
	margin-top: 2vh;
}
.selectStyle{
	margin-right: 10px;
	margin-top: 2vh;
}
.radioBtn{
	width: 48px;
	height: 3vh;
}
.SelectText{
	display:inline-block;
	white-space: nowrap;
	width: 4.5vw;
	text-align: left;
	padding-left: 16px;
	font-size: 16px;
}
//表格
.timeTable{
	// width: 97%;
	height: 47vh;
	// margin-left: 2%;
	margin-top:2vh;
}
.Startuptime,.cycle{
    width:100%;
    height:auto;
  }
  .Startuptime_l{
    display:inline-block;
    width:16%;
    height:3vh;
	font-size: 14px;
	@include font_color("font2_color_white");
    text-align: center;
    float:left;
    // border:1px solid #bbbbbb;
		@include border2_color("border2_color_white");
	@include background_color("background5_color_white");
    border-right:none;
    border-bottom: none;
    line-height: 3vh;
  }
  .Startuptime_r{
      display:inline-block;
      width:83%;
      height:3vh;
      float:left;
      text-align: center;
      // border:1px solid #bbbbbb;
			@include border2_color("border2_color_white");
	  @include background_color("background5_color_white");
      border-bottom: none;
      line-height: 3vh;
  }
  .cycle_l{
    display:inline-block;
    width:16%;
    height:37vh;
    text-align: center;
    float:left;
	font-size: 14px;
	@include font_color("font2_color_white");
    // border:1px solid #bbbbbb;
		@include border2_color("border2_color_white");
	@include background_color("background5_color_white");
    border-right:none;
    line-height: 40vh;
  }
  .cycle_r{
      display:inline-block;
      width:83%;
      height:37vh;
      float:left;
      text-align: center;
      position: relative;
      // border:1px solid #bbbbbb;
			@include border2_color("border2_color_white");
	  @include background_color("background5_color_white");
      margin-bottom: 18px;
  }
  .radiostyle{
    width: 100%;
    white-space: nowrap;
    text-align: left;
    position: absolute;
    top:1.4vh;
    left: 12px;
  }
  .checkboxstyle{
    width: 100%;
    white-space: nowrap;
    top:1vh;
    position: absolute;
    text-align: left;
    top:3.7vh;
    left: 12px;
  }
  .SelectInput{
	  display: inline-block;
  	  width: 10vw;
  	  height: 3vh;
  	  line-height: 3vh;
  	  text-align: left;
	  font-size: 15px;
	  @include border2_color("border2_color_white");
		background-color: #1f2930 !important;
  }
  .SelectInput span{
  	  display: inline-block;
  	  margin-left: 10px;
  }
  .btn{
  	  float: right;
  	  margin-top: 2vh;
  }
}
</style>