<template>
	<div class="produce">
		<el-row :gutter="20">
		  <el-col :span="25">
		    <div class="RowDIv RowBck1" 
				v-loading="loading"
				element-loading-text="加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(1,34,48, 0)">
		      <span>{{DateTime}}产量(只)</span>
			  <span v-if="OverViewListData!=null">{{OverViewListData.ProductAmount}}</span>
			  <img :src="img1"/>
		    </div>
		  </el-col>
		  <el-col :span="25">
		    <div class="RowDIv RowBck2"
			   v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
		      <span>计划完成率(%)</span>
		      <span v-if="OverViewListData!=null">{{OverViewListData.CompletePercent}}</span>
		      <img :src="img2"/>
		    </div>
		  </el-col>
		  <el-col :span="25">
		    <div class="RowDIv RowBck3"
			   v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
		      <span>良品产量(只)</span>
		      <span v-if="OverViewListData!=null">{{OverViewListData.GoodAmount}}</span>
		      <img :src="img3"/>
		    </div>
		  </el-col>
		  <el-col :span="25">
		    <div class="RowDIv RowBck4"
			   v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
		      <span>NG品产量(只)</span>
		      <span v-if="OverViewListData!=null">{{OverViewListData.NGAmount}}</span>
		      <img :src="img4"/>
		    </div>
		  </el-col>
		  <el-col :span="25">
		    <div class="RowDIv RowBck5"
			   v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
		      <span>良品率(%)</span>
		      <span v-if="OverViewListData!=null">{{OverViewListData.GoodPercent}}</span>
		      <img :src="img5"/>
		    </div>
		  </el-col>
		</el-row>
		<el-row :gutter="20">
		  <el-col :span="12">
		   <div class="Yieldcomparison_l" v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
			 <div class="gaugeoutfit">
			   <span class="xian"></span>
			   <span class="title">设备产量对比分析</span>
			 </div>  		 
			 	<div class="comparison_bck">
			 <productChart ref="productChart1" :chartData="EquipmentListDate"></productChart>
				</div>

			 <span v-show="emptyMsg" class="icon">
			   <img :src="empty" class="icon-img">
			   <div class="icon-text">暂无数据</div>
			 </span>
		   </div>
		  </el-col>
		  <el-col :span="12">
		  	<div class="Yieldcomparison_r" v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
		  	  <div class="gaugeoutfit">
			   <span class="xian"></span>
			   <span class="title">程序产量对比分析</span>
		  	  </div>	
					<div class="comparison_bck">
			  <productChart2 ref="productChart2" :chartData="ProductListDate"></productChart2> 
					</div>
			  
			  <span v-show="emptyMsg" class="icon">
			    <img :src="empty" class="icon-img">
			    <div class="icon-text">暂无数据</div>
			  </span>
		  	</div>
		  </el-col>
		</el-row>
		<el-row :gutter="20">
		  <el-col :span="24">
			  <div class="Trendanalysis" v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
				<div class="gaugeoutfit2">
				 <span class="xian"></span>
				 <span class="title">设备产量趋势分析</span>
				</div>
				<div class="comparison_bck">
				<StackingPolylines ref="StackingPolylines" :chartData="AmountListDate"></StackingPolylines>
				</div>
				<span v-show="emptyMsg" class="icon">
				  <img :src="empty" class="icon-img">
				  <div class="icon-text">暂无数据</div>
				</span>
			  </div>
		  </el-col>
		</el-row>
	</div>
</template>

<script>
import productChart from './charts/Linebar'
import productChart2 from './charts/Linebar2'
import StackingPolylines from './charts/StackingPolylines'
import empty from '@/assets/icons/empty02.png'
export default {
  name: "produce",
  props: {
    EquipmentId:{
      type: [String,Number],
      default: ''
    },
	OverViewList:{
		type: [String,Object],
		default: ''
	},
	EquipmentList:{ type:[String,Object], default: () => { return '' } },
	ProductList:{ type:[String,Object], default: () => { return '' } },
	AmountList:{ type:[String,Object], default: () => { return '' } },
	
	loading:{ type:Boolean, default: () => { return true } },
	emptyMsg:{ type:Boolean, default: () => { return true } },
	DateTime:{
		type: [String],
		default: ''
	}
  },
  components: {
	  productChart,
	  productChart2,
	  StackingPolylines
	},
  data() {
    return {
		empty:empty,
		img1:require('../../../static/images/Todayproduction.png'),
		img2:require('../../../static/images/completionrate.png'),
		img3:require('../../../static/images/Goodproductyield.png'),
		img4:require('../../../static/images/NGproduction.png'),
		img5:require('../../../static/images/YIELD.png'),
		
		OverViewListData:null,
		EquipmentListDate:null,
		ProductListDate:null,
		AmountListDate:null,
    };
  },
  watch: {
	EquipmentId(newValue) {
	   	// console.log(newValue,'treeId')
	},
	OverViewList(newValue){
		this.OverViewListData = newValue;
	},
	EquipmentList(newValue){
		// console.log(newValue,'EquipmentList')
		this.EquipmentListDate = newValue
	},
	ProductList(newValue){
		this.ProductListDate = newValue
	},
	AmountList(newValue){
		this.AmountListDate = newValue
	}
  },
  mounted() {
	  
  },
  methods: {
	  
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/mixin.scss';
::v-deep .el-col-25{
	width: 20%;
}
.RowBck1{
	background: url('../../../static/images/sssj_1.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.RowBck2{
	background: url('../../../static/images/sssj_1.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.RowBck3{
	background: url('../../../static/images/sssj_1.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.RowBck4{
	background: url('../../../static/images/sssj_1.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.RowBck5{
	background: url('../../../static/images/sssj_1.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.comparison_bck{
	background: url('../../../static/images/sbcldbfx.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	height: calc(100% - 40px);
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
.RowDIv{
	position: relative;
	width: 100%;
	height: 100px;
	@include background_color("background_color_white");
	border-radius: 4px;
}
.RowDIv span:nth-child(1){
	position: absolute;
	top: 18px;
	left: 20px;
	font-size: 16px;
	// @include font_color("font8_color_white");
	color: #fff;
}
.RowDIv span:nth-child(2){
	position: absolute;
	top: 52px;
	left: 20px;
	font-size: 26px;
	// font-weight: 600;
	@include font_color("font10_color_white");
	font-family: 'GemunuLibre-Regular';
}
.RowDIv img{
	position: absolute;
	top: 20px;
	right: 22px;
	display: inline-block;
	width: 60px;
	height: 60px;
}
.Yieldcomparison_l{
	position: relative;
	width: 100%;
	height: 220px;
	margin-top: 20px;
	@include background_color("background_color_white");
	border-radius: 4px;
}
.Yieldcomparison_r{
	position: relative;
	width: 100%;
	height: 220px;
	margin-top: 20px;
	@include background_color("background_color_white");
	border-radius: 4px;
}
.Trendanalysis{
	position: relative;
	width: 100%;
	height: 24px;
	margin-top: 20px;
	@include background_color("background_color_white");
	border-radius: 4px;
}
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
.RowDIv{
	position: relative;
	width: 100%;
	height: 10vh;
	@include background_color("background_color_white");
	border-radius: 4px;
}
.RowDIv span:nth-child(1){
	position: absolute;
	top: 1.8vh;
	left: 20px;
	font-size: 16px;
	// @include font_color("font8_color_white");
	color: #fff;
}
.RowDIv span:nth-child(2){
	position: absolute;
	top: 5.2vh;
	left: 20px;
	font-size: 26px;
	// font-weight: 600;
	@include font_color("font10_color_white");
	font-family: 'GemunuLibre-Regular';
}
.RowDIv img{
	position: absolute;
	top: 2vh;
	right: 22px;
	display: inline-block;
	width: 60px;
	height: 60px;
}
.Yieldcomparison_l{
	position: relative;
	width: 100%;
	height: 22vh;
	margin-top: 2vh;
	@include background_color("background_color_white");
	border-radius: 4px;
}
.Yieldcomparison_r{
	position: relative;
	width: 100%;
	height: 22vh;
	margin-top: 2vh;
	@include background_color("background_color_white");
	border-radius: 4px;
}
.Trendanalysis{
	position: relative;
	width: 100%;
	height: 24vh;
	margin-top: 20px;
	@include background_color("background_color_white");
	border-radius: 4px;
}
}
</style>