<template>
  <div class="condition">
    <span class="timeSpan" v-if="label">{{label}}：</span>
    <el-date-picker
      :pickerOptions="pickerOptions"
      v-model="value"
      type="month"
      :editable="false"
      :clearable="false"
      style="display:inline-block;width:10vw !important;"
      @change="selectedDate"
      placeholder="选择月">
    </el-date-picker>
  </div>
</template>
<script>
import {formatDate} from '@/common/js/date.js'
import {isEmpty} from '@/common/js/base.js'

export default {
  props: {
    MeterTypeId:String,
    label:{
      type: [String],
      default: true
    },
    month: {
      type: [String,Number],
      default: ''
    }
  },
  data () {
    return {
      value:this.month,
      // value:this.global.month,
      pickerMinDate: '',
      pickerOptions: {//禁用未来日期
          onPick: ({ maxDate, minDate }) => {
            this.pickerMinDate = minDate.getTime()
            if (maxDate) {
              this.pickerMinDate = ''
            }
          },
          disabledDate: (time) => {
            if (this.pickerMinDate !== '') {
              const day30 = (30 - 1) * 24 * 3600 * 1000
              let maxTime = this.pickerMinDate + day30
              if (maxTime > new Date()) {
                maxTime = new Date()
              }
              return time.getTime() > maxTime
            }
            return time.getTime() > Date.now()
          }
        }
    }
  },
  watch: {
	  
  },
  mounted () {
  },
  beforeDestroy () {

  },
  methods: {
    selectedDate(e){//用户手动改变时触发
      this.$emit("selectedDate",e);
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixin.scss';
.condition{
  width: auto;
}
.timeSpan{
	@include font_color("font_color_white");
	white-space: nowrap;
	width: 4.5vw;
	text-align: left;
	padding-left: 16px;
	display:inline-block;
}
</style>
