<template>
  <!-- 需要设置改div的宽度和长度才能显示 -->
  <div key="NumberCharts" :id="num+'Numbermain'"></div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex'
export default {
  props: {
	  FailureData: {
		type: [String,Object],
		default: function() {
		 return '';
		}
	  },
	  color: {
		  type: String,
		  default: function() {
		   return '';
		  }
	  },
	  num:{
		type: Number,
		default: function() {
		 return '';
		}
	  }
  },
  data() {
    return {
		myChart:null,
		FailureDataList:[],
		BackgroundImg:sessionStorage.getItem('BgColorType'),
		lineStyleColor:'',
		Legendcolor:'',
	}
  },
  computed:{
    ...mapGetters(['expansion'])
  },
  watch: {
    expansion(v){
			if (this.myChart) {
				this.myChart.resize();
			}
    },
	'$store.state.testTxt.tips'(newVal,oldVal){
		if(newVal=='white'){
			this.lineStyleColor = '#f6f6f6';
			this.Legendcolor = '#333333';
		}else{
			this.lineStyleColor = '#2a333a';
			this.Legendcolor = '#ffffff';
		}
		this.initEcharts();
	},
	FailureData(newValue){
	   this.FailureDataList = newValue.Measure;
	   document.getElementById(this.num+'Numbermain').style.display='none';
	   if(this.FailureDataList.length>0){
		   document.getElementById(this.num+'Numbermain').style.display='block';
		   this.initEcharts(); 
	   }
	}
  },
  mounted() {
    //初始echarts颜色
    if(this.BackgroundImg=='white'){
    	this.lineStyleColor = '#f6f6f6';
    	this.Legendcolor = '#333333'
    }else{
    	this.lineStyleColor = '#2a333a';
    	this.Legendcolor = '#ffffff';
    }
  },
  methods: {
    initEcharts() {
      // 在组件中直接使用this.$echarts来进行操作
	  document.getElementById(this.num+'Numbermain').style.width='100%';
	  document.getElementById(this.num+'Numbermain').style.height='100%';
	  this.$echarts.init(document.getElementById(this.num+'Numbermain')).dispose();
	  this.myChart = this.$echarts.init(document.getElementById(this.num+'Numbermain'));
      // 指定图表的配置项和数据
      var option = {
			  tooltip: {
				trigger: 'axis',
				axisPointer: {
				  type: 'shadow'
				}
			  },
			  legend: {
				x:'right',      //可设定图例在左、右、居中
				y:'top',     //可设定图例在上、下、居中
				itemWidth: 12, // 标签宽度为20px
				itemHeight: 12,
				top:10,
				padding:[0,30,0,0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
				data: [],
				textStyle: {
					color:this.Legendcolor
				}
			  },
			  grid: {  //方法 2
				x:10, 
				y:35, 
				x2:10, 
				y2:15, 
				containLabel: true 
			  },
			  xAxis: [{
				type: 'value',
				boundaryGap: [0, 0.01],
				axisPointer: {
					type: 'shadow'
				},
				axisTick:{
				   show:false
				},
				axisLine: {
				  lineStyle: {
					  width: 0,
					  type: "solid"
				  }
				},
				axisLabel: {
				  textStyle: {
					  color:this.Legendcolor
				  }
				},
				splitLine:{
					show:true,
					lineStyle:{
					//这里输入线条的样式
					 color:this.lineStyleColor,
					}
				}
			  }],
			  yAxis: [{
				type: 'category',
				data: this.FailureDataList[0].name,
				interval: 50,
				nameLocation: "middle",
				nameGap: 35,
				axisLabel: {
				  textStyle: {
					color:this.Legendcolor
				  }
				},
				axisTick:{
				   show:false
				},
				axisLine: {
				  lineStyle: {
					  width: 0,
					  type: "solid",
				  }
				},
				splitLine:{
					show:false,
					lineStyle:{
					//这里输入线条的样式
					  color:this.lineStyleColor,
					}
				}
			  }],
			  series: [{
				  nameTextStyle:{//y轴上方单位的颜色
				  	color:this.Legendcolor
				  },
				  type: 'bar',
				  itemStyle: {
				    color: this.color,
				    barBorderRadius: 8//柱子的圆角
				  },
				  data:this.FailureDataList[0].data,
				  barWidth: 8,
				  label: { //柱体上显示数值
				      show: true,//开启显示
				      position: 'right',//在上方显示
				      textStyle: {//数值样式
				          fontSize: '7px',
				          color:this.Legendcolor
				      },
				      formatter: '{c}',
				  }
				}]
			};
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
	  window.addEventListener("resize", () => {
		this.initEcharts()
		this.myChart.resize();
	  })
    },
  },
};
</script>

<style>
</style>
