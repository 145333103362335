<template>
	<div class="produce">
		<el-row :gutter="20">
		  <el-col :span="12">
		    <div class="RowDIv" 
				v-loading="loading"
				element-loading-text="加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(1,34,48, 0)">
				<div class="gaugeoutfit">
				  <span class="xian"></span>
				  <span class="title">设备故障时间统计</span>
				</div>
				<div class="comparison_bck">
					<FaultStatistics ref="FaultStatistics" :echartData="echartData"></FaultStatistics>
					<span v-show="emptyMsg" class="icon">
						<img :src="empty" class="icon-img">
						<div class="icon-text">暂无数据</div>
					</span>
				</div>
		    </div>
		  </el-col>
		  <el-col :span="6">
		    <div class="RowDIv"
			   v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
			   <div class="gaugeoutfit">
			     <span class="xian"></span>
			     <span class="title">设备故障次数前10</span>
			   </div>
				 <div class="comparison_bck">
					<FailureNumber ref="FailureNumber1" :FailureData="FailureData" :num="num" :color="color"></FailureNumber>
					<span v-show="emptyMsg" class="icon">
						<img :src="empty" class="icon-img">
						<div class="icon-text">暂无数据</div>
					</span>
				 </div>
		    </div>
		  </el-col>
		  <el-col :span="6">
		    <div class="RowDIv"
			   v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
		       <div class="gaugeoutfit">
		         <span class="xian"></span>
		         <span class="title">设备故障时长前10</span>
		       </div>
				<div class="comparison_bck">
					<FailureNumber ref="FailureNumber2" :FailureData="FailureData2" :num="num2" :color="color2"></FailureNumber>
					<span v-show="emptyMsg" class="icon">
						<img :src="empty" class="icon-img">
						<div class="icon-text">暂无数据</div>
					</span>
				</div>
		    </div>
		  </el-col>
		</el-row>
		<el-row :gutter="20">
		  <el-col :span="12">
		   <div class="Yieldcomparison_l"
		   v-loading="loading"
		   element-loading-text="加载中"
		   element-loading-spinner="el-icon-loading"
		   element-loading-background="rgba(1,34,48, 0)">
			 <div class="gaugeoutfit">
			   <span class="xian"></span>
			   <span class="title">设备故障类型分析</span>
			 </div>  		 
			 <div class="comparison_bck">
				<ComprehensivePie ref="ComprehensivePie1" :color="color3" :ComprehensiveData="ComprehensiveData" :num="num3" :name1="name1"></ComprehensivePie>
				<span v-show="emptyMsg" class="icon">
					<img :src="empty" class="icon-img">
					<div class="icon-text">暂无数据</div>
				</span>
			 </div>
		   </div>
		  </el-col>
		  <el-col :span="12">
		  	<div class="Yieldcomparison_r"
			v-loading="loading"
			element-loading-text="加载中"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(1,34,48, 0)">
		  	  <div class="gaugeoutfit">
			   <span class="xian"></span>
			   <span class="title">设备故障代码分析</span>
		  	  </div>	
			<div class="comparison_bck">
			 <ComprehensivePie ref="ComprehensivePie2" :color="color4" :ComprehensiveData="ComprehensiveData2" :num="num4" :name1="name2"></ComprehensivePie>
			 <span v-show="emptyMsg" class="icon">
			   <img :src="empty" class="icon-img">
			   <div class="icon-text">暂无数据</div>
			 </span>
			</div>
		  	</div>
		  </el-col>
		</el-row>
	</div>
</template>

<script>
import FaultStatistics from './charts/FaultStatistics'
import FailureNumber from './charts/FailureNumber'
import ComprehensivePie from './charts/ComprehensivePie'
import empty from '@/assets/icons/empty02.png'

export default {
  name: "produce",
  props: {
    EquipmentId:{
      type: [String,Number],
      default: ''
    },
	EqTroubleByMonth: {
	  type: [String,Object],
	  default: function() {
	   return '';
	  }
	},
	EqTroubleCodeMap: {
	  type: [String,Object],
	  default: function() {
	   return '';
	  }
	},
	EqTroubleTypeMap: {
	  type: [String,Object],
	  default: function() {
	   return '';
	  }
	},
	EqtroubleTimesTop10: {
	  type: [String,Object],
	  default: function() {
	   return '';
	  }
	},
	EqtroubleDuringTop10: {
	  type: [String,Object],
	  default: function() {
	   return '';
	  }
	},
	loading:{ type:Boolean, default: () => { return true } },
	emptyMsg:{ type:Boolean, default: () => { return true } },
  },
  components: {
	  FaultStatistics,
	  FailureNumber,
	  ComprehensivePie
	},
  data() {
    return {
		empty:empty,
		echartData:null,
		color:'#38cb89',
		num:1,
		FailureData:null,
		color2:'#ff9600',
		num2:2,
		FailureData2:null,
		color3:['#38cb89',"#009cff",'#ff9600','#f14848','#cccccc'],
		ComprehensiveData:null,
		name1:'设备故障类型',
		num3:3,
		color4:['#38cb89',"#009cff",'#ff9600','#f14848','#cccccc'],
		ComprehensiveData2:null,
		name2:'设备故障代码',
		num4:4,
    };
  },
  watch: {
	EquipmentId(newValue) {
	   	// console.log(newValue,'treeId0')
	},
	//设备故障时间统计
	EqTroubleByMonth(newValue){
		this.echartData = newValue;
	},
	//设备故障次数前10
	EqtroubleDuringTop10(newValue){
		this.FailureData = newValue;
	},
	//设备故障时长前10
	EqtroubleTimesTop10(newValue){
		this.FailureData2 = newValue;
	},
	//设备故障类型分析
	EqTroubleTypeMap(newValue){
		this.ComprehensiveData = newValue;
	},
	//设备故障代码分析
	EqTroubleCodeMap(newValue){
		this.ComprehensiveData2 = newValue;
	}
  },
  mounted() {
	  
  },
  methods: {
	  
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/mixin.scss';
.comparison_bck{
	background: url('../../../static/images/sbcldbfx.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	height: calc(100% - 40px);
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
.RowDIv{
	position: relative;
	width: 100%;
	height: 335px;
	// @include background_color("background_color_white");
	border-radius: 4px;
}

.Yieldcomparison_l{
	position: relative;
	width: 100%;
	height: 245px;
	margin-top: 20px;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.Yieldcomparison_r{
	position: relative;
	width: 100%;
	height: 245px;
	margin-top: 20px;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
.RowDIv{
	position: relative;
	width: 100%;
	height: 33.5vh;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.Yieldcomparison_l{
	position: relative;
	width: 100%;
	height: 24.5vh;
	margin-top: 2vh;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.Yieldcomparison_r{
	position: relative;
	width: 100%;
	height: 24.5vh;
	margin-top: 2vh;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
}
</style>