<template>
  <!-- 需要设置改div的宽度和长度才能显示 -->
  <div key="RingCharts" :id="num+'Ringmain'"></div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex'
import {convert} from '@/common/js/base.js'
export default {
  props: {
	  color: {
		  type: Array,
		  default: function() {
		   return [];
		  }
	  },
	  ComprehensiveData: {
		  type: [String,Object],
		  default: function() {
		   return '';
		  }
	  },
	  name1: {
		  type: String,
		  default: ''
	  },
	  num: {
	  		type:Number,
	  		default: ''
	  }
  },
  data() {
    return {
		myChart:null,
		ComprehensiveDataList:[],
		BackgroundImg:sessionStorage.getItem('BgColorType'),
		lineStyleColor:'',
		Legendcolor:'',
	}
  },
  computed:{
    ...mapGetters(['expansion'])
  },
  watch: {
    expansion(v){
			if (this.myChart) {
				this.myChart.resize();
			}
    },
	'$store.state.testTxt.tips'(newVal,oldVal){
		if(newVal=='white'){
			this.lineStyleColor = '#f6f6f6';
			this.Legendcolor = '#333333';
		}else{
			this.lineStyleColor = '#2a333a';
			this.Legendcolor = '#ffffff';
		}
		this.initEcharts();
	},
	ComprehensiveData(newValue){
		this.ComprehensiveDataList = [];
	   for(let i=0;i<newValue.Measure.length;i++){
		   for(let j=0;j<newValue.Measure[i].name.length;j++){
			  let obj ={
			  	value:newValue.Measure[i].data[j],
				name:newValue.Measure[i].name[j],
			  } 
			this.ComprehensiveDataList.push(obj)
		   }
	   }
	   document.getElementById(this.num+'Ringmain').style.display='none';
	   if(this.ComprehensiveDataList.length>0){
		   document.getElementById(this.num+'Ringmain').style.display='block';
		   this.initEcharts(); 
	   }
	}
  },
  mounted() {
	//初始echarts颜色
	if(this.BackgroundImg=='white'){
		this.lineStyleColor = '#f6f6f6';
		this.Legendcolor = '#333333'
	}else{
		this.lineStyleColor = '#2a333a';
		this.Legendcolor = '#ffffff';
	}
  },
  methods: {
    initEcharts() {
      // 在组件中直接使用this.$echarts来进行操作
	  document.getElementById(this.num+'Ringmain').style.width='100%';
	  document.getElementById(this.num+'Ringmain').style.height='100%';
	  this.$echarts.init(document.getElementById(this.num+'Ringmain')).dispose();
	  this.myChart = this.$echarts.init(document.getElementById(this.num+'Ringmain'));
	  var color= this.ComprehensiveData.Colors;
      // 指定图表的配置项和数据
      var option ={
		  color:color,
		  tooltip: {
			trigger: 'item'
		  },
		  legend: {
			// itemGap: 20,
			orient: 'vertical',
			height: convert(40),
			itemWidth: 12, // 标签宽度为20px
			itemHeight: 12,
			left: '40%',
			top: '20%',
			bottom: convert(20),
			"textStyle": {
				"fontSize":12
			},
			formatter:function (name){
				let data = option.series[0].data;
				// console.log(data, 'data')
				let total = 0
				let tarValue
				for (let i = 0; i < data.length; i++) {
					total += data[i].value
					if (data[i].name == name) {
					tarValue = data[i].value
					}
				}
				// let v = tarValue + 'G'
				//计算出百分比
				let p = Math.round((tarValue / total) * 100) + '%'  
				return `${name}  ${p} `
			},
			textStyle: {
				color:this.Legendcolor
			}
		  },
		  grid: {  //方法 2
			x:0, 
			y:convert(10), 
			x2:convert(10), 
			y2:convert(60), 
			containLabel: true 
		  },
		  graphic: [{ //环形图中间添加文字
		      type: 'text', //通过不同top值可以设置上下显示
		      left: '14%',
		      top: '45%',//加10
		      style: {
		          text:this.name1,
		          textAlign: 'center',
		          fill: this.Legendcolor, //文字的颜色
		          fontSize:convert(11),
		          lineHeight: convert(16),
		      }
		  }],
		  series: [
			{
			  type: 'pie',
			  radius: ['40%', '70%'],
              center: ['20%', '48%'],//加10
			  avoidLabelOverlap: false,
			  hoverAnimation:false,   //关闭放大动画
			  label: {
				show: false,
				position: 'center'
			  },
			  labelLine: {
				show: false
			  },
			  data: this.ComprehensiveDataList
			}
		  ]
		};
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
	  window.addEventListener("resize", () => {
		this.initEcharts()
		this.myChart.resize();
	  })
    },
  },
};
</script>

<style>
</style>
