<template>
  <div class="condition"
    v-loading="loading"
    element-loading-text="列表加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(1,34,48, 0)"
    >
    <span class="SelectText"><span class="red">*</span>计划名称：</span>
    <el-select v-model="value" @change="selectedLocations" :style="{width:width}" filterable allow-create
              placeholder="请选择">
      <el-option
        v-for="(item, index) in list"
        :key="index"
        :label="item.DayWorkPlanName"
        :value="item.Id">
      </el-option>
    </el-select>
  </div>
</template>
<script>
import {isEmpty} from '@/common/js/base.js'
import { GetDayWorkNames } from "@/api/EquipmentOperationMonitoring"; //页面接口

export default {
  props: {
	EquipmentId:{
	  type: [String,Number],
	  default: ''
	},
  },
  data () {
    return {
      value:'',
      width:'10vw',
      list:[],
      loading:false,
	  distinguish:true,
	  zbldname:'',
	  msgId:null
    }
  },
  watch: {//关联查询
	EquipmentId(newValue) {
		this.EquipmentId = newValue;
		this.$nextTick(() => {
		  this.getLocations()
		})
	},
  },
  mounted () {
    this.$nextTick(() => {
      this.getLocations()
    })
  },
  beforeDestroy () {
  },
  methods: {
	getMsg(msgId){
		//刷新列表
		this.msgId = msgId;
		this.getLocations(this.msgId)
	},
    selectedLocations(e){
		let resultArr = this.list.filter((item)=>{
			return item.Id === e
		});
		if(resultArr.length>0){
			this.distinguish = true;
			this.zbldname = resultArr[0].DayWorkPlanName;
		}else{
			this.distinguish = false;
			this.zbldname = e;
		}
      this.$emit("selectedLocations",e,this.zbldname,this.distinguish);
    },
    getLocations(msgId){
        this.list = [];
        this.loading = true;
		GetDayWorkNames({
			agencyId:sessionStorage.getItem('agencyId'),
			EquipmentId:this.EquipmentId
		}).then(res => {
		let obj = res.Content;
		 if(res.Code==-1) {
		   this.loading = false;
		   this.value = '';
		   this.$notify.info({
		     title:'消息',
		     message: '获取计划名称失败'
		   });
		   return;
		 }else if(obj.length == 0) {
		   this.loading = false;
		   this.value = '';
		   return;
		 }
		 //添加和更新回显用
		 if(msgId!=null){
			 if(this.distinguish){
				this.value = msgId;
				this.list = obj;
				this.zbldname = obj[0].DayWorkPlanName;
				this.loading = false;
				this.$emit("selectedLocations",this.value,this.zbldname,this.distinguish); 
			 }else{ //新添加
				let ArrId = obj.filter((item)=>{
					return item.DayWorkPlanName === msgId;
				});
				this.list = obj;
				this.loading = false;
				this.value = Number(ArrId[0].Id);
				this.zbldname = ArrId[0].DayWorkPlanName;
				this.$emit("selectedLocations",this.value,this.zbldname,true); 
			 }
		   
		 }else{
			 // list.push(list.shift());//第一个元素放在后面
			 this.list = obj;
			 this.value = obj[0].Id;
			 this.zbldname = obj[0].DayWorkPlanName;
			 this.loading = false;
			 this.$emit("selectedLocations",this.value,this.zbldname,this.distinguish);
		 }
		}).catch(err => {
			this.loading = false;
			this.value = '';
		})
    }
  }
}
</script>

<style scoped>
.condition{
  display: inline-block;
}
.SelectText{
	position: relative;
	display:inline-block;
	white-space: nowrap;
	width: 4.5vw;
	text-align: left;
	padding-left: 16px;
	font-size: 16px;
}
.red{
	position: absolute;
	color: #eb4b4b;
	left: 5px;
	top: 3px;
}
</style>
