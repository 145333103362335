<template>
  <div class="xs-date">
  	<div class="xs-date-title">
    <div class="date-year-prev">
     << </div>
        <div class="date-prev">
          < </div>
            <div class="date-time"></div>
            <div class="date-next"> > </div>
            <div class="date-year-next"> >> </div>
        </div>
        <div class="xs-date-week">
          <div>周日</div>
          <div>周一</div>
          <div>周二</div>
          <div>周三</div>
          <div>周四</div>
          <div>周五</div>
          <div>周六</div>
        </div>
        <div class="xs-date-day">
        </div>
    </div>
</template>

<script>
import {formatDate} from '@/common/js/date.js'
import {isEmpty, getBrowserLang} from '@/common/js/base.js'

export default {
  props: {
    DateTime:{ type: Array, default: () => { return [] } },
    BeginTime2:{
      type: [String,Date],
      default: ''
    },
    EndTime2:{
      type: [String,Date],
      default: ''
    }
  },
 components: {

 },
 data() {
   return {
    time: formatDate(new Date(), 'yyyy-MM-dd'),
    test:false,
   }
 },
 computed: {},
 watch: {
  DateTime(newValue) {
    if(this.DateTime=== "" || this.BeginTime2=== "" || this.EndTime2=== "") return;
    // console.log(this.DateTime,this.BeginTime2,this.EndTime2,'7')
    var d = new Date(this.BeginTime2);
    var begintime=d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    var c = new Date(this.EndTime2);
    var endtime=c.getFullYear() + '-' + (c.getMonth() + 1) + '-' + c.getDate();
     this.inits(this.DateTime,begintime,endtime);
  },
  BeginTime2(newValue) {
    if(this.DateTime=== "" || this.BeginTime2=== "" || this.EndTime2=== "") return;
    var d = new Date(this.BeginTime2);
    var begintime=d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    var c = new Date(this.EndTime2);
    var endtime=c.getFullYear() + '-' + (c.getMonth() + 1) + '-' + c.getDate();
     if (new Date(begintime).getTime() > new Date(endtime).getTime()) {
       this.$notify.info({
         title: '消息',
         message: '开始时间不能大于结束时间'
       });
       return;
     }
     this.inits(this.DateTime,this.BeginTime2,this.EndTime2);
  },
  EndTime2(newValue) {
   if(this.DateTime=== "" || this.BeginTime2=== "" || this.EndTime2=== "") return;
   var d = new Date(this.BeginTime2);
   var begintime=d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
   var c = new Date(this.EndTime2);
   var endtime=c.getFullYear() + '-' + (c.getMonth() + 1) + '-' + c.getDate();
   if (new Date(begintime).getTime() > new Date(endtime).getTime()) {
     this.$notify.info({
       title: '消息',
       message: '开始时间不能大于结束时间'
     });
     return;
   }
    this.inits(this.DateTime,begintime,endtime);
  }
 },
 mounted() {
  // console.log(this.DateTime,this.BeginTime2,this.EndTime2,'4')
  if(this.DateTime!=null){
    this.inits(this.DateTime,this.BeginTime2,this.EndTime2);
  }
 },
 methods: {
  inits(arr,begintime,endtime){
    let that = this;
    var d = new Date(begintime);
    var c = new Date(endtime);
    var begintime=d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    var endtime=c.getFullYear() + '-' + (c.getMonth() + 1) + '-' + c.getDate();
    var _this = this;
    let date_time = document.querySelector(".date-time");
    let prev = document.querySelector(".date-prev");
    let next = document.querySelector(".date-next");
    let prev_year = document.querySelector(".date-year-prev");
    let next_year = document.querySelector(".date-year-next");
    let date_day = document.querySelector(".xs-date-day");

    // 初始
    // let date = new Date() //当前时间
    let date = d //当前时间
    // console.log(new Date(),date,'date')
    function updateTime() {

      let year = date.getFullYear(); //当前年份
      let month = date.getMonth() + 1; //当前月
      month < 10 ? month = "0" + month : month;
      let day = date.getDate(); //当前天
      day < 10 ? day = "0" + day : day;

      date_day.innerHTML = "";
      date_time.innerText = year + "年 " + month + "月"; //标题时间
      var setDate = new Date(year, month, 0);
      var setDay = setDate.getDate(); //这个月天数
      var setWeek = new Date(year, month - 1, 1).getDay(); //上个月星期几
      var setDayEM = new Date(year, month - 1, 0).getDate(); //上个月天数
      //渲染上个月星期
      setWeek <= 0 ? setWeek = 7 : setWeek;
      for (let i = (setDayEM - setWeek) + 1; i <= setDayEM; i++) {
        let EmptyDiv = document.createElement('div');
        EmptyDiv.innerText = i;
        EmptyDiv.className = "Disable";
        date_day.appendChild(EmptyDiv);
      }

      // 渲染日期
      for (let i = 1; i <= setDay; i++) {
        let TimeDiv = document.createElement('div');
        TimeDiv.innerText = i;
        TimeDiv.className = "item-time";
        let yearmoth = year+'-'+month+'-'+i;
        getweekday(yearmoth);//注意此处必须是先new一个Date
        // console.log(yearmoth,getweekday(yearmoth),'日期');
        let statr = begintime;
        let end = endtime;

        isDuringDate(statr,end,yearmoth)
        // ifTimeRange(statr,end,yearmoth)
        // console.log(statr,end,yearmoth,isDuringDate(statr,end,yearmoth),'1')
        // if (i != day) {
        //   TimeDiv.classList.add("active");
        // }
        if(isDuringDate(statr,end,yearmoth)){
          if(arr.includes(getweekday(yearmoth))){
            TimeDiv.classList.add("active");
          }
          // else{
          //   console.log(arr,"不存在");
          // }

          // if (getweekday(yearmoth) != '星期一'&&getweekday(yearmoth) != '星期五') {
          //   TimeDiv.classList.add("active");
          // }
        }else{
           TimeDiv.classList.add("actives");
        }
        date_day.appendChild(TimeDiv);
      }
      //
      function getweekday(date){
        var weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
        var week = weekArray[new Date(date).getDay()];//注意此处必须是先new一个Date
         return "周"+week;
      }
      // 判断所选时间是否在某一时间段
      function isDuringDate(beginDateStr, endDateStr,nowTime) {
              var curDate = new Date(nowTime),
                  beginDate = new Date(beginDateStr),
                  endDate = new Date(endDateStr);
              if (curDate >= beginDate && curDate <= endDate) {
                  return true;
              }
              return false;
      }
      // function ifTimeRange(beginTime, endTime, nowTime) {
      //   var strb = beginTime.split('-')
      //   if (strb.length !== 3) {
      //     return false
      //   }

      //   var stre = endTime.split('-')
      //   if (stre.length !== 3) {
      //     return false
      //   }

      //   var strn = nowTime.split('-')
      //   if (stre.length !== 3) {
      //     return false
      //   }
      //   var b = new Date()
      //   var e = new Date()
      //   var n = new Date()

      //   b.setYear(strb[0])
      //   b.setMonth(strb[1])
      //   b.setDate(strb[2])
      //   e.setYear(stre[0])
      //   e.setMonth(stre[1])
      //   e.setDate(stre[2])
      //   n.setYear(strn[0])
      //   n.setMonth(strn[1])
      //   n.setDate(strn[2])

      //   if (n.getDate() - b.getDate() > 0 && n.getDate() - e.getDate() < 0) {
      //     return true
      //   } else {
      //     return false
      //   }
      // }
      // 渲染尾部
      for (let i = 1; i <= (42 - setWeek - setDay); i++) {
        let DisDiv = document.createElement('div');
        DisDiv.innerText = i;
        DisDiv.className = "Disable";
        date_day.appendChild(DisDiv);
      }
      itemClick(year,month);
    }

    updateTime();
    prev.onclick = function() {
      date.setMonth(date.getMonth() - 1);
      updateTime();
    };
    next.onclick = function() {
      date.setMonth(date.getMonth() + 1);
      updateTime();
    };
    prev_year.onclick = function() {
      date.setYear(date.getFullYear() - 1);
      updateTime();
    };
    next_year.onclick = function() {
      date.setYear(date.getFullYear() + 1);
      updateTime();
    };

    function itemClick(year,month) {
      // let item_time = document.querySelectorAll(".item-time");
      // for (let i = 0; i < item_time.length; i++) {
      //   item_time[i].onclick = function() {
      //     for (let i = 0; i < item_time.length; i++) {
      //       item_time[i].classList.remove("active");
      //     }
      //     this.classList.add("active");
      //     this.time = year + "-" + month + "-"+this.innerText;
      //     _this.$emit("TimeSelect",this.time);
      //   }
      // }
    }
    this.$emit("TimeSelect",this.time);
  }
 },
}
</script>

<style lang="scss">
@import '@/assets/mixin.scss';
.xs-date {
  @include font_color("font_color_white");
  border: none;
  @include background_color("background7_color_white");
  border-radius: 4px;
  line-height: 20px;
  // margin: 74px auto 0;
  margin: 74px 1% 0;
  width: 94%;
  height: 272px;
  padding: 10px;
}

.xs-date-title {
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  @include font_color("font9_color_white");
  font-size: 16px;
}

.date-prev {
  width: 15%;
  font-family: 'SourceHanSansCN-Regular';
}

.date-year-prev {
  width: 15%;
  font-family: 'SourceHanSansCN-Regular';
}

.date-time {
  width: 40%;
}

.date-next {
  width: 15%;
  font-family: 'SourceHanSansCN-Regular';
}

.date-year-next {
  width: 15%;
  font-family: 'SourceHanSansCN-Regular';
}

.xs-date-week {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: none;
}

.xs-date-week div {
  list-style: none;
  width: calc(100%/7);
  height: 30px;
  line-height: 30px;
  text-align: center;
  @include font_color("font9_color_white");
  font-size: 14px;
}

.xs-date-day {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}

.xs-date-day div {
  width: calc(100%/7);
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.date-prev:hover,
.date-next:hover,
.date-time:hover,
.xs-date-day div:hover {
  color:#38cb89;
}

.xs-date .active {
  display: inline-block;
  width: calc(100%/7);
  height: 30px;
  line-height: 30px;
  text-align: center;
  @include background_color("background8_color_white");
  @include font_color("font9_color_white");
}
.xs-date .actives {
  display: inline-block;
  width: calc(100%/7);
  height: 30px;
  line-height: 30px;
  text-align: center;
  @include background_color("background7_color_white");
  color: transparent;
}
.xs-date .Disable {
  color: #C0C4CC !important;
}
@media only screen and (max-aspect-ratio: 1920/920) {
  .xs-date {
    @include font_color("font_color_white");
    border: none;
    @include background_color("background7_color_white");
    border-radius: 4px;
    line-height: 20px;
    margin:74px auto 0;
    width: 94%;
    height: 250px;
    padding: 10px;
  }
  .xs-date-week {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: none;
  }
  .xs-date-day div {
    width: calc(100%/7);
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .xs-date .active {
    display: inline-block;
    width: calc(100%/7);
    height: 30px;
    line-height: 30px;
    text-align: center;
    @include background_color("background8_color_white");
    @include font_color("font9_color_white");
  }
  .xs-date .actives {
    display: inline-block;
    width: calc(100%/7);
    height: 30px;
    line-height: 30px;
    text-align: center;
    @include background_color("background7_color_white");
    color: transparent !important;
  }
}
@media only screen and (min-aspect-ratio: 1920/920) {
  .xs-date {
    @include font_color("font_color_white");
    border: none;
    @include background_color("background7_color_white");
    border-radius: 4px;
    line-height: 2vh;
    // margin: 74px auto 0;
    margin: 74px 1% 0;
    width: 96%;
    // margin-left:2%;
    height: 25vh;
    padding: 10px;
  }
  .xs-date-week {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.5vh;
    padding-bottom: 0.5vh;
    border-bottom: 1px solid #304f5f;
  }
  .xs-date-day div {
    width: calc(100%/7);
    height: 3vh;
    line-height: 3vh;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }
  .xs-date .active {
    display: inline-block;
    width: calc(100%/7);
    height: 3vh;
    line-height: 3vh;
    text-align: center;
    @include background_color("background8_color_white");
    @include font_color("font9_color_white");
  }
  .xs-date .actives {
    display: inline-block;
    width: calc(100%/7);
    height: 3vh;
    line-height: 3vh;
    text-align: center;
    @include background_color("background7_color_white");
    color: transparent !important;
  }
}
</style>
