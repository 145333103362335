<template>
	 <!-- // EqUseRate EqErrRate EqProduceLoadRate EqRunningRate EqOEE EqProduceRate EqPerformanceRate EqOKRate -->
	<div class="produce">
		<el-row :gutter="20" class="elRow">
		  <el-col :span="6">
		    <div class="RowDIv" 
				v-loading="loading"
				element-loading-text="加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(1,34,48, 0)">
		      <span>设备利用率(%)</span>
			  <span v-if="percentageObjDate!=null">{{percentageObjDate.EqUseRate?percentageObjDate.EqUseRate:0}}</span>
				<div class="RowDIv_sp"></div>
		    </div>
		  </el-col>
		  <el-col :span="6">
		    <div class="RowDIv"
			   v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
		      <span>设备故障率(%)</span>
		      <span v-if="percentageObjDate!=null">{{percentageObjDate.EqErrRate?percentageObjDate.EqErrRate:0}}</span>
				<div class="RowDIv_sp"></div>
		    </div>
		  </el-col>
		  <el-col :span="6">
		    <div class="RowDIv"
			   v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
		      <span>产能负荷率(%)</span>
		      <span v-if="percentageObjDate!=null">{{percentageObjDate.EqProduceLoadRate?percentageObjDate.EqProduceLoadRate:0}}</span>
		    <div class="RowDIv_sp"></div>
				</div>
		  </el-col>
		  <el-col :span="6">
		    <div class="RowDIv"
			   v-loading="loading"
			   element-loading-text="加载中"
			   element-loading-spinner="el-icon-loading"
			   element-loading-background="rgba(1,34,48, 0)">
		      <span>设备生产效率(件/天)</span>
		      <span v-if="percentageObjDate!=null">{{percentageObjDate.EqProduceRate?percentageObjDate.EqProduceRate:0}}</span>
		    </div>
		  </el-col>
		</el-row>
		<el-row :gutter="20">
		  <el-col :span="7">
		   <div class="Yieldcomparison_l">
				<div class="gaugeoutfit">
					<span class="xian"></span>
					<span class="title">设备综合效率</span>
				</div>  		 
				<!--进度条-->
				<div class="comparison_bck">
					<div class="progress">
						<div class="progress_l">设备综合效率OEE</div>
						<div class="progress_r" v-if="percentageObjDate!=null">{{percentageObjDate.EqOEE?percentageObjDate.EqOEE:0}} %</div>
						<span v-if="percentageObjDate!=null">
							<el-progress :percentage="percentageObjDate.EqOEE>100?100:percentageObjDate.EqOEE" status="success" :text-inside="false" :stroke-width="8" ></el-progress>
						</span>
					</div>
					<!--圆环百分比-->
					<div class="pic"
					v-loading="loading"
					element-loading-text="加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(1,34,48, 0)">
						<div class="pic1" v-if="PicDate1.length!=0">
						<Picbar ref="Picbar1" :color="color" :num="num" :PicDate="PicDate1"></Picbar>
						<span style="white-space: nowrap;">时间稼动率(%)</span>
						</div>
						<div class="pic1" v-if="PicDate3.length!=0">
							<Picbar ref="Picbar2" :color="color2" :num="num2" :PicDate="PicDate2"></Picbar>
							<span style="white-space: nowrap;">性能稼动率(%)</span>
						</div>
						<div class="pic1" v-if="PicDate3.length!=0">
							<Picbar ref="Picbar3" :color="color3" :num="num3" :PicDate="PicDate3"></Picbar>
							<span style="white-space: nowrap;">一次合格率(%)</span>
						</div>
						<span v-show="emptyMsg" class="icon">
							<img :src="empty" class="icon-img">
							<div class="icon-text">暂无数据</div>
						</span>
					</div>
				</div>
			</div>
		  </el-col>
		  <el-col :span="17">
		  	<div class="Yieldcomparison_r"
			v-loading="loading"
			element-loading-text="加载中"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(1,34,48, 0)">
				<div class="gaugeoutfit2">
			   <span class="xian"></span>
			   <span class="title">设备利用率趋势</span>
				</div>	
				<div class="comparison_bck">
					<Foldline ref="Foldline" :FoldDate="FoldDate"></Foldline> 
					<span v-show="emptyMsg" class="icon">
						<img :src="empty" class="icon-img">
						<div class="icon-text">暂无数据</div>
					</span>
				</div>
		  	</div>
		  </el-col>
		</el-row>
		<el-row :gutter="20">
		  <el-col :span="24">
			  <div class="Trendanalysis"
			  v-loading="loading"
			  element-loading-text="加载中"
			  element-loading-spinner="el-icon-loading"
			  element-loading-background="rgba(1,34,48, 0)">
				<div class="gaugeoutfit2">
				 <span class="xian"></span>
				 <span class="title">设备产能负荷趋势</span>
				</div>
				<div class="comparison_bck">
					<Foldline2 ref="Foldline2" :FoldDate="FoldDate2"></Foldline2>
					<span v-show="emptyMsg" class="icon">
						<img :src="empty" class="icon-img">
						<div class="icon-text">暂无数据</div>
					</span>
				</div>
			  </div>
		  </el-col>
		</el-row>
	</div>
</template>

<script>
import Foldline from './charts/Foldline'
import Foldline2 from './charts/Foldline2'
import Picbar from './charts/Picbar'
import empty from '@/assets/icons/empty02.png'

export default {
  name: "produce",
  props: {
    EquipmentId:{
      type: [String,Number],
      default: ''
    },
	
	percentageObj:{ type:[String,Object], default: () => { return '' } },
	EqLoadRateMapList:{ type:[String,Object], default: () => { return '' } },
	EqUsingRateMap:{ type:[String,Object], default: () => { return '' } },
	
	loading:{ type:Boolean, default: () => { return true } },
	emptyMsg:{ type:Boolean, default: () => { return true } },
  },
  components: {
	  Foldline,
	  Foldline2,
	  Picbar
	},
  data() {
    return {
		empty:empty,
		echartData:["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"],
		color:{
			color:'#009cff',
			color1:'#64c3ff'
		},
		PicDate1:[],
		num:1,
		color2:{
			color:'#fc9400',
			color1:'#ffb955'
		},
		PicDate2:[],
		num2:2,
		color3:{
			color:'#38cb89',
			color1:'#4bdc9b'
		},
		PicDate3:[],
		num3:3,
		
		FoldDate:null,
		FoldDate2:null,
		percentageObjDate:null
    };
  },
  watch: {
	EquipmentId(newValue) {
	   	// console.log(newValue,'treeId')
	},
	percentageObj(newValue){
		// console.log(newValue,newValue.EqUseRate);
		this.percentageObjDate = newValue;
		this.PicDate1 = newValue.EqRunningRate==undefined?[]:newValue.EqRunningRate;
		this.PicDate2 = newValue.EqPerformanceRate==undefined?[]:newValue.EqRunningRate;
		this.PicDate3 = newValue.EqOKRate==undefined?[]:newValue.EqRunningRate;
	},
	EqLoadRateMapList(newValue){
		this.FoldDate = newValue
	},
	EqUsingRateMap(newValue){
		this.FoldDate2 = newValue
	},
  },
  mounted() {
		
  },
  methods: {
	  //解决小数相乘精度问题的方法
		// mulNum(arg1, arg2){
		// 	let m = 0, s1 = arg1.toString(), s2 = arg2.toString();
		// 		try {
		// 			m += s1.split(".")[1].length;
		// 		}
		// 		catch (e) {
		// 		}
		// 		try {
		// 			m += s2.split(".")[1].length;
		// 		}
		// 		catch (e) {
		// 		}
		// 		return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
		// },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/mixin.scss';
.elRow{
	// @include background_color("background_color_white");
	background: url('../../../static/images/sbzhxl_bck.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	border-radius: 4px;
	margin: 0!important;
}
.RowDIv{
	position: relative;
}
.RowDIv_sp{
	background: url('../../../static/images/sbzhxl_sp.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: absolute;
	right: 2px;
	top: 0;
	width: 2px;
	height: 100%;
}
.comparison_bck{
	background: url('../../../static/images/sbcldbfx.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	height: calc(100% - 40px);
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
.RowDIv{
	position: relative;
	width: 100%;
	height: 100px;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.RowDIv span:nth-child(1){
	position: absolute;
	top: 18px;
	left: 90px;
	font-size: 16px;
	// @include font_color("font8_color_white");
	color: #fff;
}
.RowDIv span:nth-child(2){
	position: absolute;
	top: 52px;
	left: 90px;
	font-size: 26px;
	// font-weight: 500;
	color: #38cb89;
	font-family: 'GemunuLibre-Regular';
}
.Yieldcomparison_l{
	width: 100%;
	height: 220px;
	margin-top: 20px;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.Yieldcomparison_r{
	position: relative;
	width: 100%;
	height: 220px;
	margin-top: 20px;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.Trendanalysis{
	position: relative;
	width: 100%;
	height: 240px;
	margin-top: 20px;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.progress{
	display: inline-block;
	width: 92%;
	height: 20px;
	margin-left:4%;
}
.progress_l{
	display: inline-block;
	width: 50%;
	height: 100%;
	font-size: 15px;
	// @include font_color("font8_color_white");
	color: #fff;
	font-weight: 600;
	text-align: left;
}
.progress_r{
	display: inline-block;
	width: 50%;
	height: 100%;
	font-size: 24px;
	// font-weight: 600;
	text-align: right;
	color: #00d392;
	font-family: 'GemunuLibre-Regular';
}
.pic{
	position: relative;
	width: 92%;
	height:100px;
	margin-left:4%;
}
.pic1{
	width: 33.33%;
	height: 100%;
	display: inline-block;
	text-align: center;
	margin-top: -8px;
}
.pic1 span{
	display: inline-block;
	font-size: 15px;
	@include font_color("font2_color_white");
	position: relative;
	bottom: 12px;
}
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
.RowDIv{
	position: relative;
	width: 100%;
	height: 10vh;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.RowDIv span:nth-child(1){
	position: absolute;
	top: 1.8vh;
	left: 90px;
	font-size: 16px;
	// @include font_color("font8_color_white");
	color: #fff;
}
.RowDIv span:nth-child(2){
	position: absolute;
	top: 5.2vh;
	left: 90px;
	font-size: 26px;
	// font-weight: 600;
	color: #38cb89;
	font-family: 'GemunuLibre-Regular';
}
.Yieldcomparison_l{
	width: 100%;
	height: 22vh;
	margin-top: 2vh;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.Yieldcomparison_r{
	position: relative;
	width: 100%;
	height: 22vh;
	margin-top: 2vh;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.Trendanalysis{
	position: relative;
	width: 100%;
	height: 24vh;
	margin-top: 20px;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.progress{
	display: inline-block;
	width: 92%;
	height: 20px;
	margin-left:4%;
}
.progress_l{
	display: inline-block;
	width: 50%;
	height: 100%;
	font-size: 15px;
	// @include font_color("font8_color_white");
	color: #fff;
	font-weight: 600;
	text-align: left;
}
.progress_r{
	display: inline-block;
	width: 50%;
	height: 100%;
	font-size: 24px;
	// font-weight: 600;
	text-align: right;
	color: #00d392;
	font-family: 'GemunuLibre-Regular';
}
.pic{
	position: relative;
	width: 92%;
	height:100px;
	margin-left:4%;
}
.pic1{
	width: 33.33%;
	height: 100%;
	display: inline-block;
	text-align: center;
	margin-top: -0.8vh;
}
.pic1 span{
	display: inline-block;
	font-size: 15px;
	@include font_color("font2_color_white");
	position: relative;
	bottom: 1.2vh;
}
}
</style>