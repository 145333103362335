<template>
  <div class="EquipmentOperationAnalysis">
	 <!-- <headers :title="Header_title"></headers>  -->
	 <el-row>
	   <el-col :span="24">
		   <div :class="activeIndex!=3?'Container FilterCriteria':'Container2 FilterCriteria' ">
			   <div class="HeaderTab">
			   		<div class="tabs" v-for="(item,index) in List" :key="index" @click="tap(index)" :class="activeIndex==index ? 'active' : ''">{{item.name}}</div>
			   </div>
				<div class="gaugeoutfit2">
					<span class="xian"></span>
					<span class="title">快速检索</span>
				</div>
				<div :class="activeIndex !=3?'DevOps_ser':'DevOps_ser2'">
			   <!--业务单元-->
			   <Activity @selectedLocations="selectedActivity" class="selectStyle"></Activity>
			   <!--厂房-->
			   <locations :locationId="location" @selectedLocations="selectedLocations" class="selectStyle"></locations>
			   <!--事业部-->
			   <Division @selectedLocations="selectedDivision" class="selectStyle"></Division>
			   <!--车间-->
			   <Workshop :AgencyId='DivisionId'  @selectedLocations="selectedWorkshop" class="selectStyle"></Workshop>
			   <!--班组-->
			   <TeamsGroups :AgencyId='WorkshopId' @selectedLocations="selectedTeamsGroups" class="selectStyle"></TeamsGroups>
			   <!--工段-->
			   <WorkshopSection :AgencyId='WorkshopId' :WorkTeamId='TeamsGroupsId' @selectedLocations="selectedWorkshopSection" class="selectStyle"></WorkshopSection>
			   <!--作业单元-->
			   <BusinessUnit :AgencyId='WorkshopId' :WorkTeamId='WorkshopSectionId' @selectedLocations="selectedBusinessUnit" class="selectStyle"></BusinessUnit>
			   <!--设备类型-->
			   <DeviceType @selectedLocations="selectedDeviceType" class="selectStyle"></DeviceType>
			   <!--设备型号-->
			   <EquipmentModel @selectedLocations="selectedEquipmentModel" class="selectStyle"></EquipmentModel>
			   <div class="inputStyle">
				<span class="SelectText2">搜索：</span>
				<el-input
					size="mini"
					placeholder="请输入关键字"
					suffix-icon="el-icon-search"
					:style="{width:width}"
					v-model="input4">
				</el-input>  
			   </div>
			   <div class="inputStyle" v-if="activeIndex!=3">
				<span class="SelectText">时间周期：</span>
				<el-radio-group v-model="dateType" size="mini">
				  <el-radio-button class="radioBtn" label="month">月</el-radio-button>
				  <el-radio-button class="radioBtn" label="week">周</el-radio-button>
				  <el-radio-button class="radioBtn" label="day">日</el-radio-button>
				  <el-radio-button class="radioBtn" label="minute">分</el-radio-button>
				</el-radio-group>
			   </div>
			   <div class="time_row" v-if="activeIndex!=3">
			     <div v-if="dateType=='month'">
					 <month @selectedDate="selecteBeginTime" :month="BeginTime" label="开始时间"></month>
			     </div>
			     <div v-if="dateType=='week'">
			       <week @selectedDate="selecteBeginTime" :day="BeginTime" :btn="false" label="开始时间"></week>
			     </div>
			     <div v-if="dateType=='day'">
			       <day @selectedDate="selecteBeginTime" :day="BeginTime" :btn="false" label="开始时间"></day>
			     </div>
				 <div v-if="dateType=='minute'">
				   <minute @selectedDate="selecteBeginTime" :minute="BeginTime" :btn="false" label="开始时间"></minute>
				 </div>
			   </div>
			   <div class="time_row" v-if="activeIndex!=3">
			     <div v-if="dateType=='month'">
			      <month @selectedDate="selectedEndTime" :month="EndTime"
			        label="结束时间"></month>
			     </div>
			     <div v-if="dateType=='week'">
					<week @selectedDate="selectedEndTime" :day="EndTime" :btn="false"
					  label="结束时间"></week> 
			     </div>
			     <div v-if="dateType=='day'">
			       <day @selectedDate="selectedEndTime" :day="EndTime" :btn="false"
			         label="结束时间"></day>
			     </div>
				 <div v-if="dateType=='minute'">
				   <minute @selectedDate="selectedEndTime" :minute="EndTime" :btn="false" label="结束时间"></minute>
				 </div>
			   </div>
			   <el-button class="btn" size="small" type="success" @click="Save()">确认</el-button>
		   </div>
		   </div>
	   </el-col>
	 </el-row>
	 <el-row>
	   <el-col :span="24">
	      <div class="Mainbody">
			 <el-row :gutter="18">
			    <!-- <el-col :span="4"> -->
			    <el-col :span="isTreeCell">
			 	   <div :class="activeIndex!=3?'grid-content_l':'grid-content_l2'" 
				  v-loading="loading"
				  element-loading-text="加载中"
				  element-loading-spinner="el-icon-loading"
				  element-loading-background="rgba(1,34,48, 0)">
			 		   <el-tree
					       v-if="dataTree.length>0"
			 			   :data="dataTree"
			 			    class="tree"
			 				:indent="0"
			 				node-key="Id"
			 			   :highlight-current = "true"
			 			    ref="tree"
			 			    default-expand-all
			 			   :props="defaultProps"
							 :check-strictly="true"
							:check-on-click-node="true"
							:expand-on-click-node="false"
						   :current-node-key="current_node_key"
			 			   @node-click="handleNodeClick">
			 			   <span slot-scope="{ node, data }" class="custom-tree-node">
			 				    <span v-if="BackgroundImg=='white'">
									<img v-if="!data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/branch_normal.png"/>
									<img v-if="!data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/branch_active.png"/>
									
									<img v-if="data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/workshopNormal.png"/>
									<img v-if="data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/workshopActive.png"/>
								</span>
								<span v-else>
									<img v-if="!data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/normal2.png"/>
									<img v-if="!data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/branch_active.png"/>
									
									<img v-if="data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/workshopNormal2.png"/>
									<img v-if="data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/workshopActive.png"/>
								</span>
			 					 <el-tooltip v-if="node.label.length>10" class="item" effect="dark" :content="node.label" placement="top-start">
			 					   <span style="margin-left: 10px">{{ node.label | ellipsis(10) }}</span>
			 					 </el-tooltip>
			 					 <span v-else style="margin-left: 10px">{{ node.label | ellipsis(10) }}</span>
								 <span v-if="data.disabled" class="disabled" @click.stop></span>
			 				</span>
			 		   </el-tree>
						 <div class="grid-content-open" v-if="isIconOpen" @click="handleTreeClick">
							<img class="grid-tree-icon" src="../../../static/images/close_icon.png" alt="" srcset="">
						 </div>
					   <span v-show="emptyMsg" class="icon">
					     <img :src="empty" class="icon-img">
					     <div class="icon-text">暂无数据</div>
					   </span>
			 	   </div>
			    </el-col>
			    <!-- <el-col :span="20"> -->
			    <el-col :span="isCoentCell">
						<div class="grid-content-close" v-if="!isIconOpen" @click="handleTreeClick">
							<img class="grid-tree-icon" src="../../../static/images/open_icon.png" alt="" srcset="">
					 	</div>
			 	   <div :class="activeIndex!=3?'grid-content_r':'grid-content_r2'" >
					   <produce 
					     v-if="activeIndex==0"
							 ref="produce"
						 :EquipmentId="EquipmentIds"
						 :DateTime="DateTime"
						 :OverViewList="ManufacturingPerformOverview"
						 :EquipmentList="AmountAnalysisByEquipment"
						 :ProductList="AmountAnalysisByProduct"
						 :AmountList="AmountTrend"
						 :loading="loading2"
						 :emptyMsg="emptyMsg2">
					    </produce>
					   <ComprehensiveEfficiency 
					   v-if="activeIndex==1" 
						 ref="ComprehensiveEfficiency"
					   :EquipmentId="EquipmentIds"
					   :percentageObj="percentageObj"
					   :EqLoadRateMapList="EqLoadRateMapList"
					   :EqUsingRateMap="EqUsingRateMap"
					   :loading="loading3"
					   :emptyMsg="emptyMsg3"
					   ></ComprehensiveEfficiency>
					   <FaultAnalysis 
					   v-if="activeIndex==2" 
						 ref="FaultAnalysis"
					   :EquipmentId="EquipmentIds"
					   :EqTroubleByMonth="EqTroubleByMonthData"
					   :EqTroubleCodeMap="EqTroubleCodeMapData"
					   :EqTroubleTypeMap="EqTroubleTypeMapData"
					   :EqtroubleTimesTop10="EqtroubleTimesTop10Data"
					   :EqtroubleDuringTop10="EqtroubleDuringTop10Data"
					   :loading="loading4"
					   :emptyMsg="emptyMsg4"
					   ></FaultAnalysis>
					   <PlannedManagement v-if="activeIndex==3&&EquipmentTypeIds!=''" :EquipmentId="EquipmentTypeIds"></PlannedManagement>
				   </div>
				</el-col>
			</el-row>
		  </div>
	   </el-col>
	 </el-row>
  </div>
</template>
<script>
import {formatDate,getNowFormatDate,getDayOfPreWeek} from '@/common/js/date.js'
import { isEmpty,convert } from '@/common/js/base.js'
import {mapGetters,mapMutations} from 'vuex'
// import headers from '@/components/header/headermain.vue'
import locations from '@/components/select/locations'
import Activity from '@/components/select/Activity'
import Division from '@/components/select/Division'
import Workshop from '@/components/select/Workshop'
import TeamsGroups from '@/components/select/TeamsGroups'
import WorkshopSection from '@/components/select/WorkshopSection'
import BusinessUnit from '@/components/select/BusinessUnit'
import DeviceType from '@/components/select/DeviceType'
import EquipmentModel from '@/components/select/EquipmentModel'

import minute from '@/components/date/minute'
import day from '@/components/date/day'
import week from '@/components/date/week'
import month from '@/components/date/month'
import year from '@/components/date/year'

import produce from './produce'
import ComprehensiveEfficiency from './ComprehensiveEfficiency'
import FaultAnalysis from './FaultAnalysis'
import PlannedManagement from './PlannedManagement'

import { GetManufacturingTree,GetManufacturingTree2,GetManufacturingPerformAnalysis,GetOEEAnalysis,GetEquipmentTroublesAnalysis } from "@/api/EquipmentOperationAnalysis"; //页面接口

import empty from '@/assets/icons/empty02.png'
import error from '@/assets/icons/error.png'
export default {
  name: "EquipmentOperationAnalysis",
  components: {
	  Activity,
	  Division,
	  Workshop,
	  TeamsGroups,
	  WorkshopSection,
	  BusinessUnit,
	  DeviceType,
	  EquipmentModel,
	  locations,
	  minute,
	  day,
	  week,
	  month,
	  year,
	  // headers,
	  produce,
	  ComprehensiveEfficiency,
	  FaultAnalysis,
	  PlannedManagement
	},
  data() {
    return {
		Header_title:'设备运行分析',
		empty:empty,
		error:error,
		ActivityId:'',
		DivisionId:'',
		WorkshopId:'',
		TeamsGroupsId:'',
		WorkshopSectionId:'',
		BusinessUnitId:'',
		DeviceTypeId:'',
		EquipmentModelId:'',
		input4:'',
		width:'10vw',
		location:'',
		current_node_key:'',
		List:[{
			name:'生产执行分析',
			id:1
		},{
			name:'设备综合效率',
			id:2
		},{
			name:'设备故障分析',
			id:3
		},{
			name:'设备运行计划管理',
			id:4
		}],
		activeIndex:0,
		dateType: 'day',
		DateTime:'日',
		SelectDate: formatDate(new Date(), 'yyyy'),
		BeginTime: formatDate(new Date(), 'yyyy-MM-dd'),
		EndTime: formatDate(new Date(), 'yyyy-MM-dd'),
		data: [],
		dataTree:[],
		defaultProps: {
		  children: 'Children',
		  label: 'Name'
		},
		EquipmentIds:'',
		EquipmentTypeIds:'',
		loading:false,
		emptyMsg:true,
		
		data2:null,
		loading2:false,
		emptyMsg2:true,
		dateTypeNumber:'',
		ManufacturingPerformOverview:null,
		AmountAnalysisByEquipment:null,
		AmountAnalysisByProduct:null,
		AmountTrend:null,
		
		loading3:true,
		emptyMsg3:false,
		data3:null,
		SearchId:0,
		SearchTypeId:0,
		BgColor:this.$store.state.testTxt.tips,
		BackgroundImg:sessionStorage.getItem('BgColorType'),
		percentageObj:{},
		EqLoadRateMapList:null,
		EqUsingRateMap:null,
		
		loading4:true,
		emptyMsg4:false,
		data4:null,
		EqTroubleByMonthData:null,
		EqTroubleCodeMapData:null,
		EqTroubleTypeMapData:null,
		EqtroubleTimesTop10Data:null,
		EqtroubleDuringTop10Data:null,

		isIconOpen:false,//默认关闭
		isTreeCell:0,
		isCoentCell:24,
    };
  },
  watch: {
  '$store.state.testTxt.tips'(newVal,oldVal){
		this.BgColor = newVal;
		if(this.BgColor=='white'){
			this.BackgroundImg = 'white';
		}else{
			this.BackgroundImg = 'black';
		}
	},
    dateType(val) {
      if (this.dateType == 'month') {
        this.SelectDate = formatDate(new Date(), 'yyyy-MM');
        this.BeginTime = formatDate(new Date(this.SelectDate), 'yyyy-MM');
        this.EndTime = formatDate(new Date(this.SelectDate), 'yyyy-MM');
      } else if (this.dateType == 'week') {
        this.SelectDate = formatDate(new Date(), 'yyyy-MM-dd');
        this.BeginTime = getDayOfPreWeek(new Date(this.SelectDate), 'yyyy-MM-dd');
        this.EndTime = formatDate(new Date(this.SelectDate), 'yyyy-MM-dd');
      } else if (this.dateType == 'day') {
        this.SelectDate = formatDate(new Date(), 'yyyy-MM-dd');
        this.BeginTime = formatDate(new Date(this.SelectDate), 'yyyy-MM-dd');
        this.EndTime = formatDate(new Date(this.SelectDate), 'yyyy-MM-dd');
      }else if (this.dateType == 'minute') {
        this.BeginTime = formatDate(new Date(), 'yyyy-MM-dd'+" 00:00:00");
        this.EndTime = formatDate(new Date(), 'yyyy-MM-dd'+" 00:00:00");
      }
    },
  },
  methods: {
	tap(index){
	  this.activeIndex=index;
	  this.SearchId = 0;
	  this.SearchTypeId = 0;
	  if(index==0){ //生产执行分析
	   this.inits();
	   this.GetManufacturingPerformAnalysis_s(); 
	  }else if(index==1){ //设备综合效率
	    this.inits();
		this.GetOEEAnalysis_s();
	  }else if(index==2){ //设备故障分析
	    this.inits();
		this.GetEquipmentTroublesAnalysis_s();
	  }else if(index==3){ //设备运行计划管理
	    this.inits2();
	  }
	},
	selectedActivity(val) {
	  this.ActivityId = val;
	},
	selectedLocations(val,name,leve){
	  this.location = val;
	},
	selectedDivision(val,name){
	  this.DivisionId = val;
	  this.BusinessName = name;
	},
	selectedWorkshop(val){
		this.WorkshopId = val;
	},
	selectedTeamsGroups(val){
		this.TeamsGroupsId = val;
	},
	selectedWorkshopSection(val){
		this.WorkshopSectionId = val;
	},
	selectedBusinessUnit(val){
		this.BusinessUnitId = val;
		this.SearchId = 0;
		this.SearchTypeId = 0;
		if(this.activeIndex==0){ //生产执行分析
		    this.inits();
			this.GetManufacturingPerformAnalysis_s();
		}else if(this.activeIndex==1){ //设备综合效率
			this.inits();
			this.GetOEEAnalysis_s();
		}else if(this.activeIndex==2){ //设备故障分析
			this.inits();
			this.GetEquipmentTroublesAnalysis_s();
		}else if(this.activeIndex==3){ //设备运行计划管理
			this.inits2();	  
		}
	},
	selectedDeviceType(val){
		this.DeviceTypeId = val; 
	},
	selectedEquipmentModel(val){
		this.EquipmentModelId = val; 
	},
	//开始时间-结束时间
	selecteBeginTime(val) {
	  if (this.dateType == 'month') {
	    this.BeginTime = formatDate(new Date(val), 'yyyy-MM');
	  } else if (this.dateType == 'week') {
	    this.BeginTime = formatDate(new Date(val), 'yyyy-MM-dd');
	  } else if (this.dateType == 'day') {
	    this.BeginTime = formatDate(new Date(val), 'yyyy-MM-dd');
	  }else if (this.dateType == 'minute') {
	    this.BeginTime = formatDate(new Date(val), 'yyyy-MM-dd hh:mm:ss');
	  }
	},
	selectedEndTime(val) {
	  if (this.dateType == 'month') {
	    this.EndTime = formatDate(new Date(val), 'yyyy-MM');
	  } else if (this.dateType == 'week') {
	    this.EndTime = formatDate(new Date(val), 'yyyy-MM-dd');
	  } else if (this.dateType == 'day') {
	    this.EndTime = formatDate(new Date(val), 'yyyy-MM-dd');
	  }else if (this.dateType == 'minute') {
	    this.EndTime = formatDate(new Date(val), 'yyyy-MM-dd hh:mm:ss');
	  }
	},
	Save(){
		if (this.dateType == 'month') {
			this.DateTime = '月';
		} else if (this.dateType == 'week') {
			this.DateTime = '周';
		} else if (this.dateType == 'day') {
			this.DateTime = '日';
		}else if (this.dateType == 'minute') {
			this.DateTime = '分';
		}
		if (new Date(this.BeginTime) > new Date(this.EndTime)) {
			this.$notify.info({
				title: '消息',
				message: '开始时间不能大于结束时间'
			});
			return;
		}
		//tree
		this.SearchId = 0;
		this.SearchTypeId = 0;
		if(this.activeIndex==0){ //生产执行分析
			this.inits();
			this.GetManufacturingPerformAnalysis_s();
		}else if(this.activeIndex==1){ //设备综合效率
			this.inits();
			this.GetOEEAnalysis_s();
		}else if(this.activeIndex==2){ //设备故障分析
			this.inits();
			this.GetEquipmentTroublesAnalysis_s();
		}else if(this.activeIndex==3){ //设备运行计划管理
			this.inits2();	  
		}
	},
	handleNodeClick (data,node) {
		if (new Date(this.BeginTime) > new Date(this.EndTime)) {
			this.$notify.info({
				title: '消息',
				message: '开始时间不能大于结束时间'
			});
			return;
		}
	  this.EquipmentIds = data.Id;
	  this.SearchId = data.Id;
	  this.SearchTypeId = data.Type;
	  if(this.activeIndex==0){ //生产执行分析
	  	this.GetManufacturingPerformAnalysis_s();
	  }else if(this.activeIndex==1){ //设备综合效率
	  	this.GetOEEAnalysis_s();
	  }else if(this.activeIndex==2){ //设备故障分析
	  	this.GetEquipmentTroublesAnalysis_s();
	  }else if(this.activeIndex==3){ //设备运行计划管理
	  	if(data.Type==5){
	  		this.EquipmentTypeIds = data.Id;
	  	}else{
	  		// this.$notify.info({
	  		//   title:'消息',
	  		//   message: '请点击设备进行筛选'
	  		// });  
	  	}		  
	  }
	},
	inits(){
		this.loading = true;
		this.emptyMsg = false;
		this.dataTree = [];
		GetManufacturingTree({
		  AgencyId:sessionStorage.getItem('agencyId'),//机构id
		  BuAgencyId:this.DivisionId,//事业部
		  WorkShopId:this.WorkshopId,//车间
		  WorkClassId:this.TeamsGroupsId,//班组
		  WorkSectionId:this.WorkshopSectionId,//工段
		  WorkUnitId:this.BusinessUnitId,//作业单元
		  EquipmentTypeId:this.DeviceTypeId,//,//设备类型
		  ModelId:this.EquipmentModelId,//厂商id-设备型号
		  LocationId:this.location,//厂房
		  KeyWords:this.input4,//关键字
		}).then(res => {
		let obj = res.Content;
		 if(res.Code==-1) {
		   this.loading = false;
		   this.emptyMsg = true;
		   this.dataTree = [];
		   this.$notify.info({
		     title:'消息',
		     message: '查询失败'
		   });
		   return;
		 }else if(obj.length == 0) {
		  this.loading = false;
		  this.emptyMsg = true;
		  this.dataTree = [];
		   this.$notify.info({
		     title: '消息',
		     message:'数据为空'
		   });
		   return;
		 }
		 this.loading = false;
		 this.dataTree = obj;
		 this.EquipmentTypeIds = 0;//obj[0].Id
		 this.$nextTick(function () {
		   this.$refs.tree.setCurrentKey(this.EquipmentTypeIds);
		 })
		}).catch(err => {
		this.loading = false;
		this.emptyMsg = true;
		})
	},
	inits2(){
		this.loading = true;
		this.emptyMsg = false;
		this.data = [];
		this.dataTree = [];
		GetManufacturingTree2({
		  AgencyId:sessionStorage.getItem('agencyId'),//机构id
		  BuAgencyId:this.DivisionId,//事业部
		  WorkShopId:this.WorkshopId,//车间
		  WorkClassId:this.TeamsGroupsId,//班组
		  WorkSectionId:this.WorkshopSectionId,//工段
		  WorkUnitId:this.BusinessUnitId,//作业单元
		  EquipmentTypeId:this.DeviceTypeId,//,//设备类型
		  ModelId:this.EquipmentModelId,//厂商id-设备型号
		  LocationId:this.location,//厂房
		  KeyWords:this.input4,//关键字
		}).then(res => {
		let obj = res.Content.tree;
		 if(res.Code==-1) {
		   this.loading = false;
		   this.emptyMsg = true;
		   this.data = [];
		   this.dataTree = [];
		   this.$notify.info({
		     title:'消息',
		     message: '查询失败'
		   });
		   return;
		 }else if(obj.length == 0) {
		  this.loading = false;
		  this.emptyMsg = true;
		  this.data = [];
		  this.dataTree = [];
		   this.$notify.info({
		     title: '消息',
		     message:'数据为空'
		   });
		   return;
		 }
		 this.loading = false;
		 this.data = obj;
		 this.EquipmentTypeIds = res.Content.DefaultEquipmentId;
		 this.dataTree = this.getNewTree(this.data);
		 this.$nextTick(function () {
		   this.$refs.tree.setCurrentKey(this.EquipmentTypeIds);
		 })
		}).catch(err => {
		this.loading = false;
		this.emptyMsg = true;
		})
	},
	// 递归遍历树,通过map遍历直接修改原数组数据，数组其他的数据不变
	getNewTree(obj){
		obj.map(item=>{
		  if(item.Children&&item.Children.length>0){
			item.disabled = true;
			this.getNewTree(item.Children)
		  }
		})
		return obj
	},
	GetManufacturingPerformAnalysis_s(){
		this.loading2 = true;
		this.emptyMsg2 = false;
		this.data2 = null;
		this.dateTypeNumber = '';
		this.ManufacturingPerformOverview = {};
		this.AmountAnalysisByEquipment = null;
		this.AmountAnalysisByProduct = null;
		this.AmountTrend = null;
		
		if(this.dateType=='month'){
		   this.dateTypeNumber = 1
		}else if(this.dateType=='week'){
			this.dateTypeNumber = 2
		}else if(this.dateType=='day'){
			this.dateTypeNumber = 3
		}else if(this.dateType=='minute'){
			this.dateTypeNumber = 4
		}
		GetManufacturingPerformAnalysis({
		  AgencyId:sessionStorage.getItem('agencyId'),//机构id
		  BuAgencyId:this.DivisionId,//事业部
		  WorkShopId:this.WorkshopId,//车间
		  WorkClassId:this.TeamsGroupsId,//班组
		  WorkSectionId:this.WorkshopSectionId,//工段
		  WorkUnitId:this.BusinessUnitId,//作业单元
		  EquipmentTypeId:this.DeviceTypeId,//,//设备类型
		  ModelId:this.EquipmentModelId,//厂商id-设备型号
		  LocationId:this.location,//厂房
		  KeyWords:this.input4,//关键字
		  StrBeginDate:this.BeginTime,
		  StrEndDate:this.EndTime,
		  DateType:this.dateTypeNumber,
		  SearchId:this.SearchId,
		  SearchTypeId:this.SearchTypeId
		}).then(res => {
		let obj = res.Content;
		 if(res.Code==-1) {
		   this.loading2 = false;
		   this.emptyMsg2 = true;
		   this.data2 = null;
		   this.$notify.info({
		     title:'消息',
		     message: '查询失败'
		   });
		   return;
		 }
		//  为空 显示 0
		 this.ManufacturingPerformOverview = obj.ManufacturingPerformOverview;
		 
		 if(obj.AmountAnalysisByEquipment.Dimension.length == 0) {
		  this.loading2 = false;
		  this.emptyMsg2 = true;
		  this.data2 = null;
		   this.$notify.info({
		     title: '消息',
		     message:'数据为空'
		   });
		   return;
		 }
		 this.loading2 = false;
		 this.data2 = obj;
		 this.AmountAnalysisByEquipment = this.data2.AmountAnalysisByEquipment;
		 this.AmountAnalysisByProduct = this.data2.AmountAnalysisByProduct;
		 this.AmountTrend = this.data2.AmountTrend;
		}).catch(err => {
		this.loading2 = false;
		this.emptyMsg2 = true;
		})
	},
	//设备综合效率
	GetOEEAnalysis_s(){
		this.loading3 = true;
		this.emptyMsg3 = false;
		this.data3 = null;
		this.percentageObj = {};
		this.EqLoadRateMapList = null;
		this.EqUsingRateMap = null;
		if(this.dateType=='month'){
		   this.dateTypeNumber = 1
		}else if(this.dateType=='week'){
			this.dateTypeNumber = 2
		}else if(this.dateType=='day'){
			this.dateTypeNumber = 3
		}else if(this.dateType=='minute'){
			this.dateTypeNumber = 4
		}
		GetOEEAnalysis({
		  AgencyId:sessionStorage.getItem('agencyId'),//机构id
		  BuAgencyId:this.DivisionId,//事业部
		  WorkShopId:this.WorkshopId,//车间
		  WorkClassId:this.TeamsGroupsId,//班组
		  WorkSectionId:this.WorkshopSectionId,//工段
		  WorkUnitId:this.BusinessUnitId,//作业单元
		  EquipmentTypeId:this.DeviceTypeId,//,//设备类型
		  ModelId:this.EquipmentModelId,//厂商id-设备型号
		  LocationId:this.location,//厂房
		  KeyWords:this.input4,//关键字
		  StrBeginDate:this.BeginTime,
		  StrEndDate:this.EndTime,
		  DateType:this.dateTypeNumber,
		  SearchId:this.SearchId,
		  SearchTypeId:this.SearchTypeId
		}).then(res => {
		let obj = res.Content;
		 if(res.Code==-1) {
		   this.loading3 = false;
		   this.emptyMsg3 = true;
		   this.data3 = null;
		   this.$notify.info({
		     title:'消息',
		     message: '查询失败'
		   });
		   return;
		 }else if(obj.EqLoadRateMap.Dimension.length == 0) {
		  this.loading3 = false;
		  this.emptyMsg3 = true;
		  this.data3 = null;
		   this.$notify.info({
		     title: '消息',
		     message:'数据为空'
		   });
		   return;
		 }
		 this.loading3 = false;
		 this.data3 = obj;
		 this.percentageObj = {
			EqErrRate:obj.EqErrRate,
			EqOEE:obj.EqOEE,
			EqOKRate:obj.EqOKRate,
			EqPerformanceRate:obj.EqPerformanceRate,
			EqProduceLoadRate:obj.EqProduceLoadRate,
			EqProduceRate:obj.EqProduceRate,
			EqRunningRate:obj.EqRunningRate,
			EqUseRate:obj.EqUseRate
		 }
		 this.EqLoadRateMapList = obj.EqUsingRateMap;
		 this.EqUsingRateMap = obj.EqLoadRateMap;
		// console.log(this.percentageObj,this.data3)
		}).catch(err => {
		this.loading3 = false;
		this.emptyMsg3 = true;
		})
	},
	//设备故障分析
	GetEquipmentTroublesAnalysis_s(){
	  this.loading4 = true;
	  this.emptyMsg4 = false;
	  this.data4 = null;
	  if(this.dateType=='month'){
	     this.dateTypeNumber = 1
	  }else if(this.dateType=='week'){
	  	this.dateTypeNumber = 2
	  }else if(this.dateType=='day'){
	  	this.dateTypeNumber = 3
	  }else if(this.dateType=='minute'){
	  	this.dateTypeNumber = 4
	  }
	  GetEquipmentTroublesAnalysis({
	    AgencyId:sessionStorage.getItem('agencyId'),//机构id
	    BuAgencyId:this.DivisionId,//事业部
	    WorkShopId:this.WorkshopId,//车间
	    WorkClassId:this.TeamsGroupsId,//班组
	    WorkSectionId:this.WorkshopSectionId,//工段
	    WorkUnitId:this.BusinessUnitId,//作业单元
	    EquipmentTypeId:this.DeviceTypeId,//,//设备类型
	    ModelId:this.EquipmentModelId,//厂商id-设备型号
	    LocationId:this.location,//厂房
	    KeyWords:this.input4,//关键字
	    StrBeginDate:this.BeginTime,
	    StrEndDate:this.EndTime,
	    DateType:this.dateTypeNumber,
	    SearchId:this.SearchId,
	    SearchTypeId:this.SearchTypeId
	  }).then(res => {
	  let obj = res.Content;
	   if(res.Code==-1) {
	     this.loading4 = false;
	     this.emptyMsg4 = true;
	     this.data4 = null;
	     this.$notify.info({
	       title:'消息',
	       message: '查询失败'
	     });
	     return;
	   }else if(obj.EqTroubleByMonth.Dimension.length == 0) {
	    this.loading4 = false;
	    this.emptyMsg4 = true;
	    this.data4 = null;
	     this.$notify.info({
	       title: '消息',
	       message:'数据为空'
	     });
	     return;
	   }
	   this.loading4 = false;
	   this.data4 = obj;
	   this.EqTroubleByMonthData = obj.EqTroubleByMonth;
	   this.EqTroubleCodeMapData = obj.EqTroubleCodeMap;
	   this.EqTroubleTypeMapData = obj.EqTroubleTypeMap;
	   this.EqtroubleTimesTop10Data = obj.EqtroubleTimesTop10;
	   this.EqtroubleDuringTop10Data = obj.EqtroubleDuringTop10;
	  }).catch(err => {
	  this.loading4 = false;
	  this.emptyMsg4 = true;
	  })
	},
	handleTreeClick(){
		this.isIconOpen = !this.isIconOpen;
		if (this.isIconOpen) {
			this.isTreeCell = 4;
			this.isCoentCell = 20;
		}else{
			this.isTreeCell = 0;
			this.isCoentCell = 24;
		}
		// 收缩树结构 resize尺寸
		if(this.activeIndex==0){
			this.$nextTick(() => {
				// console.log(this.$refs.produce);
				let productChart1 = this.$refs.produce.$refs.productChart1.myChart;
				let productChart2 = this.$refs.produce.$refs.productChart2.myChart;
				let StackingPolylines = this.$refs.produce.$refs.StackingPolylines.myChart;
				if (productChart1) {//防止无数据时.resize()报错
					productChart1.resize();
				}
				if(productChart2){
					productChart2.resize();
				}
				if(StackingPolylines){
					StackingPolylines.resize();
				}
			});
		}else if(this.activeIndex == 1){
			this.$nextTick(() => {
				// console.log(this.$refs.ComprehensiveEfficiency);
				let Picbar1Chart = this.$refs.ComprehensiveEfficiency.$refs.Picbar1.myChart;
				let Picbar2Chart = this.$refs.ComprehensiveEfficiency.$refs.Picbar2.myChart;
				let Picbar3Chart = this.$refs.ComprehensiveEfficiency.$refs.Picbar3.myChart;
				let FoldlineChart = this.$refs.ComprehensiveEfficiency.$refs.Foldline.myChart;
				let Foldline2Chart = this.$refs.ComprehensiveEfficiency.$refs.Foldline2.myChart;
				if (Picbar1Chart) {//防止无数据时.resize()报错
					Picbar1Chart.resize();
				}
				if(Picbar2Chart){
					Picbar2Chart.resize();
				}
				if(Picbar3Chart){
					Picbar3Chart.resize();
				}
				if(FoldlineChart){
					FoldlineChart.resize();
				}
				if(Foldline2Chart){
					Foldline2Chart.resize();
				}
			});
		}else if(this.activeIndex == 2){
			this.$nextTick(() => {
				let FaultStatisticsChart = this.$refs.FaultAnalysis.$refs.FaultStatistics.myChart;
				let FailureNumber1Chart = this.$refs.FaultAnalysis.$refs.FailureNumber1.myChart;
				let FailureNumber2Chart = this.$refs.FaultAnalysis.$refs.FailureNumber2.myChart;
				let ComprehensivePie1Chart = this.$refs.FaultAnalysis.$refs.ComprehensivePie1.myChart;
				let ComprehensivePie2Chart = this.$refs.FaultAnalysis.$refs.ComprehensivePie2.myChart;
				if (FaultStatisticsChart) {//防止无数据时.resize()报错
					FaultStatisticsChart.resize();
				}
				if(FailureNumber1Chart){
					FailureNumber1Chart.resize();
				}
				if(FailureNumber2Chart){
					FailureNumber2Chart.resize();
				}
				if(ComprehensivePie1Chart){
					ComprehensivePie1Chart.resize();
				}
				if(ComprehensivePie2Chart){
					ComprehensivePie2Chart.resize();
				}
			})
		}
	},
  },
};
</script>
<style scoped="scoped" lang="scss">
@import '@/assets/mixin.scss';
.EquipmentOperationAnalysis{
	width: 100%;
	height: 100%;
}
.SelectText{
	display:inline-block;
	white-space: nowrap;
	width: 4.5vw;
	text-align: left;
	padding-left: 16px;
	font-size: 16px;
}
.SelectText2{
	display:inline-block;
	white-space: nowrap;
	width: 2.7vw;
	text-align: left;
	padding-left: 16px;
	font-size: 16px;
}
/*tree的虚线样式*/
.el-tree{
	padding: 10px;
	font-size: 15px;
	background-color: transparent;
	@include font_color("font2_color_white");
}
/*tree的虚线样式*/
::v-deep .tree{
  .el-tree-node {
    position: relative;
    padding-left: 6px; // 缩进量
  }
  .el-tree-node__children {
    padding-left: 10px; // 缩进量
  }
  .el-tree-node__expand-icon{
	margin-left: 4px!important;
  }
  .tree-element {
  	background-color: transparent;
  	outline: none;
  	cursor: default;
  }
.el-tree-node__content{
  &:hover{
    background-color: transparent;
	color: #38cb89;
  }
  }
  // 竖线
  .el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: 3px;
    top: -26px;
    border-width: 1px;
    border-left: 1px dashed #bbbbbb;
  }
  // 当前层最后一个节点的竖线高度固定
  .el-tree-node:last-child::before {
    height: 38px; // 可以自己调节到合适数值
  }

  // 横线
  .el-tree-node::after {
    content: "";
    width: 15px;
    height: 20px;
    position: absolute;
    left:3px;
    top: 12px;
    border-width: 1px;
    border-top: 1px dashed #bbbbbb;
  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  & > .el-tree-node::after {
    border-top: none;
  }
  & > .el-tree-node::before {
    border-left: none;
  }
	
  // 展开关闭的icon
  .el-tree-node__expand-icon{
    font-size: 15px;
    // 叶子节点（无子节点）
    &.is-leaf{
      color: transparent;
      // display: none; // 也可以去掉
			&:before{
        display: none;
      }
    }
  }
  .el-tree-node:focus>.el-tree-node__content{ //选中
    background-color: transparent;
  }
}
.custom-tree-node {
  position: relative;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
.disabled {
  display: inline-block;
  cursor: not-allowed;
  position: absolute;
  height: 27px;
  width: 210px;
  background: transparent!important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
.Container{
	width: 100%;
	height: 270px;
	border-radius: 4px;
	// @include background_color("background_color_white");
}
.Container2{
	width: 100%;
	height: 220px;
	border-radius: 4px;
	// @include background_color("background_color_white");
}
.DevOps_ser{
	background: url('../../../static/images/search.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	width: 100%;
	height: 170px;
}
.DevOps_ser2{
	background: url('../../../static/images/search.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	width: 100%;
	height: 120px;
}
.HeaderTab{
	width: 98%;
	height: 40px;
	margin-left: 15px;
	line-height: 40px;
	font-size:18px;
	// @include border_color("border_color_white");
	margin-bottom: 20px;
}
.tabs{
	min-width:100px;
	padding-left: 0px;
	padding-right: 0px;
	height: 40px;
	line-height: 40px;
	width: 240px;
	margin-right: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	background: url('../../../static/images/tab_normal.png');
	// background-size: 100% 100%;
	background-repeat: no-repeat;
}
.active{
	color: #3bffc3!important;
	// border-bottom: 3px solid #3bffc3;
	background: url('../../../static/images/tab_active.png');
	// background-size: 100% 100%;
	background-repeat: no-repeat;
}
.inputStyle{
	display: inline-block;
	margin-right: 10px;
	margin-top: 20px;
}
.selectStyle{
	margin-right: 10px;
	margin-top: 20px;
}
.time_row{
	display: inline-flex;
	font-size: 16px;
	padding-right: 10px;
	margin-top:20px;
}
.radioBtn{
	width: 48px;
	height: 30px;
}
.btn{
	margin-left: 20px;
}
.Mainbody{
	width: 100%;
	height: 610px;
	margin-top: 15px;
}
.grid-content_l{
	position: relative;
	width: 100%;
	height: 600px;
	border-radius: 5px;
	// @include background_color("background_color_white");
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.grid-content_l2{
	position: relative;
	width: 100%;
	height: 650px;
	border-radius: 5px;
	// @include background_color("background_color_white");
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.grid-content_r{
	width: 100%;
	height: 600px;
	border-radius: 4px;
}
.grid-content_r2{
	width: 100%;
	height: 650px;
	border-radius: 4px;
}
.grid-tree-icon{
	width: 10px;
	height: 12px;
	display: inline-block;
}
.grid-content-open{
	width: 18px;
	height: 80px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(100%, -60%);
	cursor: pointer;
	background-color: #062e3a;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}
.grid-content-close{
	width: 18px;
	height: 80px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(-4px, -60%);
	cursor: pointer;
	background-color: #062e3a;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
.disabled {
  display: inline-block;
  cursor: not-allowed;
  position: absolute;
  height: 2.7vh;
  width: 210px;
  background: transparent!important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
.Container{
	width: 100%;
	height: 27vh;
	border-radius: 4px;
	// @include background_color("background_color_white");
}
.Container2{
	width: 100%;
	height: 22vh;
	border-radius: 4px;
	// @include background_color("background_color_white");
}
.DevOps_ser{
	background: url('../../../static/images/search.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	width: 100%;
	height: 170px;
}
.DevOps_ser2{
	background: url('../../../static/images/search.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	width: 100%;
	height: 120px;
}
.HeaderTab{
	width: 98%;
	height: 4vh;
	margin-left: 15px;
	line-height: 4vh;
	font-size:18px;
	// @include border_color("border_color_white");
	margin-bottom: 2vh;
}
.tabs{
	min-width:100px;
	padding-left: 0px;
	padding-right: 0px;
	height: 4vh;
	line-height: 4vh;
	width: 240px;
	background: url('../../../static/images/tab_normal.png');
	// background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-right: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
}
.active{
	color: #3bffc3!important;
	// border-bottom: 3px solid #3bffc3;
	background: url('../../../static/images/tab_active.png');
	// background-size: 100% 100%;
	background-repeat: no-repeat;
}
.inputStyle{
	display: inline-block;
	margin-right: 10px;
	margin-top: 2vh;
}
.selectStyle{
	margin-right: 10px;
	margin-top: 2vh;
}
.time_row{
	display: inline-flex;
	font-size: 16px;
	padding-right: 10px;
	margin-top:2vh;
}
.radioBtn{
	width: 48px;
	height: 3vh;
}
.btn{
	margin-left: 20px;
}
.Mainbody{
	width: 100%;
	height: 61vh;
	margin-top:1.5vh;
}
.grid-content_l{
	position: relative;
	width: 100%;
	height: 60vh;
	border-radius: 5px;
	// @include background_color("background_color_white");
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.grid-content_l2{
	position: relative;
	width: 100%;
	height: 65vh;
	border-radius: 5px;
	// @include background_color("background_color_white");
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.grid-content_r{
	width: 100%;
	height: 60vh;
	border-radius: 4px;
}
.grid-content_r2{
	width: 100%;
	height: 65vh;
	border-radius: 4px;
}
.grid-tree-icon{
	width: 10px;
	height: 12px;
	display: inline-block;
}
.grid-content-open{
	width: 18px;
	height: 8vh;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(100%, -60%);
	cursor: pointer;
	background-color: #062e3a;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}
.grid-content-close{
	width: 18px;
	height: 8vh;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(-4px, -60%);
	cursor: pointer;
	background-color: #062e3a;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}
}
</style>